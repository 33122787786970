import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as S from "./LoginForm.styles";
import { notificationController } from "../../../controllers/notificationController";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import * as Auth from "../../layouts/AuthLayout/AuthLayout.styles";
import { doLogin } from "../../../store/slices/authSlice";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import logo from "../../../assets/images/auth/LOGO High Quality.png";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { BASE_COLORS } from "../../../styles/constants";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { useResponsive } from "../../../hooks/useResponsive";
import { BaseCol } from "../../common/BaseCol/BaseCol";

interface LoginFormData {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);

    // Adjust the API URL dynamically
    const apiLoginURL = `/user_login`;

    dispatch(doLogin({ loginPayload: values, apiURL: apiLoginURL }))
      .unwrap()
      .then(() => navigate("/choose"))
      .catch((err: any) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const desktopLayout = (
<>
      <S.LoginSide span={14}>
      <S.LogoImageContainer style={{marginLeft: '10px'}}>
            <S.LogoImage src={logo} preview={false} alt="User Image" />
          </S.LogoImageContainer>
        <Auth.FormTitle style={{color: "#000000"}}>{t("common.login")}</Auth.FormTitle>
        
        <S.LoginForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          style={{ marginTop: "3vh" }}
        >
          <Auth.FormItem
            name="email"
            label={t("common.email")}
            rules={[
              { required: true, message: t("common.requiredField") },
              {
                type: "email",
                message: t("common.notValidEmail"),
              },
            ]}
          >
            <Auth.FormInput prefix={<MailOutlined />} placeholder={t("common.email")} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t("common.password")}
            name="password"
            rules={[{ required: true, message: t("common.requiredField") }]}
          >
            <Auth.FormInputPassword prefix={<LockOutlined />} placeholder={t("common.password")} />
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
              <Auth.FormCheckbox>
                <S.RememberMeText>{t("login.rememberMe")}</S.RememberMeText>
              </Auth.FormCheckbox>
            </BaseForm.Item>
            <Link to="/auth/forgot-password">
              <S.ForgotPasswordText>
                {t("common.forgotPass")}
              </S.ForgotPasswordText>
            </Link>
          </Auth.ActionsWrapper>

          <BaseForm.Item style={{ padding: "0 8rem 0 8rem" }}>
            <Auth.SubmitButton
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ width: "10rem"}}
            >
              {t("common.loginButtom")}
            </Auth.SubmitButton>
          </BaseForm.Item>
        </S.LoginForm>
      </S.LoginSide>
      <S.Contact span={10}>
        <BaseTypography.Title style={{color: BASE_COLORS.white, fontSize: '3rem'}}>Nouveau Ici ?</BaseTypography.Title>
        <BaseTypography.Text style={{color: BASE_COLORS.white, fontSize: '1rem', padding: '0 5rem 0 5rem'}}>Contactez notre équipe de support client et nous vous aiderons à être opérationnel en un rien de temps.</BaseTypography.Text>
            <BaseButton style={{width: "12rem", marginTop: '3rem'}}>Contactez-nous</BaseButton>
      </S.Contact>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseCol>
      <S.LoginMobile >
      <S.LogoImageContainer style={{
        position: 'absolute',
        top: 0,
        left: 0,
        marginTop: '10px',
        marginLeft: '10px',
        maxWidth: '150px'
      }}>
            <S.LogoImage src={logo} preview={false} alt="User Image" />
          </S.LogoImageContainer>
        <Auth.FormTitle style={{color: "#000000"}}>{t("common.login")}</Auth.FormTitle>
        
        <S.LoginForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          style={{ marginTop: "3vh" }}
        >
          <Auth.FormItem
            name="email"
            label={t("common.email")}
            rules={[
              { required: true, message: t("common.requiredField") },
              {
                type: "email",
                message: t("common.notValidEmail"),
              },
            ]}
          >
            <Auth.FormInput prefix={<MailOutlined />} placeholder={t("common.email")} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t("common.password")}
            name="password"
            rules={[{ required: true, message: t("common.requiredField") }]}
          >
            <Auth.FormInputPassword prefix={<LockOutlined />} placeholder={t("common.password")} />
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
              <Auth.FormCheckbox>
                <S.RememberMeText>{t("login.rememberMe")}</S.RememberMeText>
              </Auth.FormCheckbox>
            </BaseForm.Item>
            <Link to="/auth/forgot-password">
              <S.ForgotPasswordText>
                {t("common.forgotPass")}
              </S.ForgotPasswordText>
            </Link>
          </Auth.ActionsWrapper>

          <BaseForm.Item style={{ padding: "0 8rem 0 8rem" }}>
            <Auth.SubmitButton
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ width: "10rem"}}
            >
              {t("common.loginButtom")}
            </Auth.SubmitButton>
          </BaseForm.Item>
        </S.LoginForm>
      </S.LoginMobile>
      <S.LoginContact>
        <BaseTypography.Title style={{color: BASE_COLORS.white, fontSize: '3rem'}}>Nouveau Ici ?</BaseTypography.Title>
        <BaseTypography.Text style={{color: BASE_COLORS.white, fontSize: '1rem', padding: '0 5rem 0 5rem'}}>Contactez notre équipe de support client et nous vous aiderons à être opérationnel en un rien de temps.</BaseTypography.Text>
            <BaseButton style={{width: "12rem", marginTop: '3rem'}}>Contactez-nous</BaseButton>
      </S.LoginContact>
    </BaseCol>
  );

  return (
    <>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

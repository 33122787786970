import React from 'react';
import * as S from './Product.styles';
import { BaseAvatar } from '../../../../common/BaseAvatar/BaseAvatar';
import { BaseCard } from '../../../../common/BaseCard/BaseCard';
import { addItemToRequest, selectProduct } from '../../../../../store/slices/ordersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../../../../../api/product.api';
import { RootState } from '../../../../../store/store';
import { BaseModal } from '../../../../common/BaseModal/BaseModal';

interface ProductCardProps {
  ProductCardData: any | null;
  isAddProduct?: boolean;
}

export const ProductCard: React.FC<ProductCardProps> = ({ ProductCardData }) => {
  const dispatch = useDispatch();
  const selectedTable = useSelector((state: RootState) => state.order.request.selectedTable);

  const handleProductClick = (product: Product, productId: number) => {
    if (selectedTable === null || selectedTable === undefined) {
      BaseModal.warning({
        title: "Vous n'avaez pas selectioner une table",
        content: "Sélectionnez un tableau s'il vous plaît avant d'ajouter des articles",
      });
    } else {
      dispatch(addItemToRequest({productId, product}));
      dispatch(selectProduct(product));
    }
  };

  return ProductCardData ? (
    <S.Wrapper 
    onClick={() => handleProductClick(ProductCardData, ProductCardData.id)}>
      <S.ProductWrapper>
      <BaseCard padding={1} style={{backgroundColor: "#01C592", borderRadius: 0}}>
        {ProductCardData.img && (
          <S.ImgWrapper>
            <BaseAvatar shape="square" src={ProductCardData?.img} alt={"Add"} />
          </S.ImgWrapper>
        )}
        {ProductCardData.name && <S.Title>{`${ProductCardData?.name}`}</S.Title>}
        {ProductCardData.prix && <S.Subtitle>{`${ProductCardData?.prix}`}</S.Subtitle>}
      </BaseCard>
      </S.ProductWrapper>
    </S.Wrapper>
  ) : null;
};

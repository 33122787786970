import React, { useEffect } from "react";
import * as S from "./PopularArticles.styles";
import { RootState } from "../../../../../store/store";
import { BaseCol } from "../../../../common/BaseCol/BaseCol";
import { BaseRow } from "../../../../common/BaseRow/BaseRow";
import { Divider, Typography } from "antd";
import { BaseSpace } from "../../../../common/BaseSpace/BaseSpace";
import { BASE_COLORS } from "../../../../../styles/constants";
import { useDispatch, useSelector } from "react-redux";
import { getPopularArticlesByDay } from "../../../../../store/slices/dashboardSlice";
import { Link } from "react-router-dom";

export const PopularArticles: React.FC = () => {
  const dispatch = useDispatch();
  const { popularArticlesbyDay } = useSelector(
    (state: RootState) => state.dashboardReducer
  );
  useEffect(() => {
    dispatch(getPopularArticlesByDay());
  }, []);

  return (
    <S.ArticlesCard padding={"1rem"}>
      <BaseRow wrap={false} justify={"space-between"}>
        <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
          Produits Populaires
        </Typography.Text>
       <Link to='reporting/popular-products'> <Typography.Text
          style={{ color: BASE_COLORS.lighGreen, cursor: "pointer" }}
        >
          Voir Tout
        </Typography.Text>
        </Link>
      </BaseRow>

      <BaseCol style={{ marginTop: "2rem" }}>
        <BaseSpace direction="vertical" size={40}>
          <S.SingleProduct direction="vertical">
            <BaseSpace direction="horizontal" size={45}>
              <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
                Rank
              </Typography.Text>
              <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
                Produit
              </Typography.Text>
            </BaseSpace>
            <S.ProductsList direction="vertical" size={0}>
              {popularArticlesbyDay?.produitsPopualires?.map((produit:any, index:any) => (
                <BaseSpace
                  direction="horizontal"
                  size={52}
                  style={{ marginTop: "1rem" }}
                >
                  <Typography.Text
                    style={{
                      marginLeft: "0.6rem",
                      color: BASE_COLORS.darkGreen,
                    }}
                  >
                    {index+1}
                  </Typography.Text>
                  <BaseSpace direction="vertical" size={3}>
                    <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
                      {produit.name}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        color: BASE_COLORS.lighGreen,
                        fontSize: "0.7vw",
                      }}
                    >
                      Commandes: {produit.somme}
                    </Typography.Text>
                  </BaseSpace>
                </BaseSpace>
              ))}
              <Divider
                orientation="center"
                style={{ backgroundColor: "#F0F0EC", width: "18vw" }}
              />
            </S.ProductsList>
          </S.SingleProduct>
        </BaseSpace>
      </BaseCol>
    </S.ArticlesCard>
  );
};

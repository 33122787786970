import jsPDF from "jspdf";

export const ReprintReceipt = (order: any) => {
  const pdfWidth = 80;
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, calculateDynamicHeight(order)],
  });

  // Set font styles
  pdf.setFont("helvetica");
  pdf.setFontSize(12); // Adjust font size as needed

  // Add content to the PDF
  const tableNumber = `Table ${order.selectedTable.numero}`;
  const tableNumberWidth = pdf.getStringUnitWidth(tableNumber) * 12; // Calculate width based on font size
  pdf.setFillColor(0, 0, 0); // Set black background color
  pdf.rect((pdfWidth - tableNumberWidth) / 2, 5, tableNumberWidth, 10, "F"); // Draw rectangle for the button
  pdf.setTextColor(255, 255, 255); // Set text color to white for button text
  pdf.setFontSize(12); // Increase font size for button text
  pdf.text("REIMPRIMER", pdfWidth / 2, 10, { align: "center" });
  pdf.setTextColor(0, 0, 0); // Reset text color to black
  pdf.setFontSize(8); // Reset font size to normal

  pdf.setFontSize(12); // Increase font size for order number and table number

  const orderNumber = `#${Math.floor(Math.random() * 100000)}`;
  pdf.text(orderNumber, pdfWidth / 2, 20, { align: "center" });
  pdf.setFont("helvetica", "bold");
  pdf.setFontSize(14);
  pdf.text(tableNumber, pdfWidth / 2, 25, { align: "center" });
  pdf.setFont("helvetica", "normal");

  pdf.text(" ", 10, 30); // Add space between table number and date
  const dateAndTime = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
  pdf.text(dateAndTime, 10, 35); // Date and time on the same line
  pdf.line(10, 40, 70, 40); // Full-width dashed line

  // Table body
  const startY = 45; // Adjust y-position
  order.items?.forEach((item: any, index: any) => {
    const yPos = startY + index * 10;
    pdf.text(`${item.quantite}  ${item.nom}`, 10, yPos);
    if (
      item.description !== null &&
      item.description !== undefined &&
      item.description !== "null"
    ) {
      // Adjust the yPos + 7 to add more space between article and description
      pdf.text(`     - ${item.description}`, 10, yPos + 5);
    }
  });

  // Save the PDF
  const pdfDataUri = pdf.output("datauristring");

  // Open a new window with the PDF content
  // Open a new window with the PDF content
  const newWindow = window.open();
  if (newWindow) {
    newWindow.document.write(
      '<iframe src="' +
        pdfDataUri +
        '" style="border:0; width:100%; height:100%;" allowfullscreen></iframe>'
    );
    newWindow.document.addEventListener("DOMContentLoaded", () => {
      // Try to print directly
      newWindow.print();
      // Close the new window after printing
      setTimeout(() => newWindow.close(), 100); // Close the window after 1 second (adjust as needed)
    });
  } else {
    alert("Please allow popups for this website");
  }
};

const calculateDynamicHeight = (order: any) => {
  const pdfMinHeight = 60; // Minimum height
  const itemHeight = 10; // Height per item
  const itemCount = order.items ? order.items.length : 0;
  const calculatedHeight = pdfMinHeight + itemCount * itemHeight;

  return calculatedHeight;
};

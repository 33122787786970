import jsPDF from "jspdf";

export const ZReport = (report: any) => {
    const {
        date_debut,
        date_fin,
        montant_total,
        // nom,  
        // nombre_commandes_annulees,
        // nombre_commandes_carte,
        // nombre_commandes_cash,
        // nombre_commandes_validees,
        nombre_total_articles,
        nombre_total_commandes,
        total_commandes_annuler,
        total_commandes_carte,
        total_commandes_cash,
        total_reduit,
        // ville,
      } = report;
      const pdfWidth = 80;
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [pdfWidth, 120],
      });
    
      // Set font styles
      pdf.setFont("helvetica");
    
      // Add content to the PDF
    
      const leftTitle = "Rapport Z";
      const rightTitleDate = new Date().toLocaleDateString();
      const rightTitleTime = new Date().toLocaleTimeString();
      const debut = new Date(date_debut);
      const fin = new Date(date_fin);
    
      // Calculate the width for centering
      const rightTitleWidth = Math.max(
        (pdf.getStringUnitWidth(rightTitleDate) * pdf.getFontSize()) /
          pdf.internal.scaleFactor,
        (pdf.getStringUnitWidth(rightTitleTime) * pdf.getFontSize()) /
          pdf.internal.scaleFactor
      );
    
      // Align the titles in the same line
      const rightTitleX = pdfWidth - rightTitleWidth;
    
      pdf.setFontSize(12);
      pdf.text(leftTitle, 5, 10);
      pdf.setFontSize(8);
      pdf.text(rightTitleDate, rightTitleX + 10, 10);
      pdf.text(rightTitleTime, rightTitleX + 7, 15);
      drawFullWidthLine(pdf, 0, 20, 100, 20);
    
      // Categories de Ventes
      pdf.text("PERIODES DU RAPPORT", pdfWidth / 2, 25, { align: "center" });
      drawFullWidthLine(pdf, 5, 27, 75, 27);
      pdf.text(
        "HEURE D'OUVERTURE                                     "+debut?.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" }),
        5,
        32
      );
      pdf.text(
        "HEURE DE FERMETURE                                    "+fin?.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" }),
        5,
        35
      );
    
      // Categories de Ventes
      pdf.text("CATEGORIES DE VENTES", pdfWidth / 2, 40, { align: "center" });
      drawFullWidthLine(pdf, 5, 42, 75, 42);
      pdf.text(
        "ESPECES                                                     "+total_commandes_cash+" DH",
        5,
        47
      );
      pdf.text(
        "CARTE BANCAIRE                                        "+total_commandes_carte+" DH",
        5,
        50
      );
      drawDashedLine(pdf, 5, 55, 75, 55, 0.5, 0.5);
    
      // Paiement Totaux
      pdf.text(
        "PAIEMENT TOTAUX                                     "+montant_total+" DH",
        5,
        60
      );
      drawFullWidthLine(pdf, 5, 65, 75, 65);
    
      // Reductions and Ventes Annules
      pdf.text(
        "REDUCTIONS                                                "+total_reduit+" DH",
        5,
        70
      );
      pdf.text(
        "VENTES ANNULES                                       "+total_commandes_annuler+"DH",
        5,
        73
      );
      drawFullWidthLine(pdf, 5, 78, 75, 78);
    
      // Vue Detaillee
      pdf.text("VUE DETAILLEE", pdfWidth / 2, 83, { align: "center" });
      drawFullWidthLine(pdf, 5, 85, 75, 85);
      pdf.text("NOMBRE DE TRANSACTIONS                              "+nombre_total_commandes, 5, 90);
      pdf.text("NOMBRE D'ARTICLES VENDUS                             "+nombre_total_articles, 5, 93);
      drawFullWidthLine(pdf, 5, 98, 75, 98);
    
      // Sommaire des Ventes
      pdf.text("SOMMAIRE DES VENTES", pdfWidth / 2, 103, { align: "center" });
      drawFullWidthLine(pdf, 5, 105, 75, 105);
      pdf.text(
        "VENTES TOTALES                                      "+montant_total+" DH",
        5,
        110
      );
    
      // Save the PDF
      pdf.save("ZReport.pdf");
    };
    
    const drawFullWidthLine = (
      pdf: jsPDF,
      x1: number,
      y1: number,
      x2: number,
      y2: number
    ) => {
      pdf.line(x1, y1, x2, y2);
    };
    
    const drawDashedLine = (
      pdf: jsPDF,
      x1: number,
      y1: number,
      x2: number,
      y2: number,
      dashLength: number,
      gapLength: number
    ) => {
      const deltaX = x2 - x1;
      const deltaY = y2 - y1;
      const numDashes = Math.floor(
        Math.sqrt(deltaX ** 2 + deltaY ** 2) / (dashLength + gapLength)
      );
      const deltaXPerDash = deltaX / numDashes;
      const deltaYPerDash = deltaY / numDashes;
    
      for (let i = 0; i < numDashes; i++) {
        if (i % 2 === 0) {
          pdf.line(
            x1 + i * deltaXPerDash,
            y1 + i * deltaYPerDash,
            x1 + (i + 1) * deltaXPerDash,
            y1 + (i + 1) * deltaYPerDash
          );
        }
      }
};

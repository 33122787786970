import axios, { AxiosError, AxiosResponse } from 'axios';
import { readToken } from '../services/localStorage.service';

export interface Product {
  id: number;
  name: string;
  prix: number;
  ingredients: any[];
  description?: string | null;
  category_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
    baseURL: apiUrl,
  });
  
  axiosInstance.interceptors.request.use((config:any) => {
    const token = readToken();
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    return config;
  });
  
  axiosInstance.interceptors.response.use(
    (response:any) => response,
    (error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('HTTP error response:', error.response.data || 'No response data available');
        return Promise.reject(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('HTTP no response:', error.request);
        return Promise.reject({ message: 'No response received from the server' });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('HTTP request setup error:', error.message);
        return Promise.reject({ message: 'Error setting up the request' });
      }
    }
  );

  export const fetchProductsApi = async (categoryId: number | undefined): Promise<Product[]> => {
    const apiUrl = `/categories/${categoryId}`; // Update the API endpoint based on your backend
  
    try {
      const response: AxiosResponse<{ articles: Product[] }> = await axiosInstance.get(apiUrl);
      return response.data.articles;
    } catch (error: any) {
      console.error('Fetch products failed', error);
      throw error.response?.data || 'Fetch products failed';
    }
  };

  export const addProductsApi = async (newProduct: Product, apiUrl: string): Promise<Product> => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.post<Product>(apiUrl, newProduct);
      return response.data.category;
    } catch (error:any) {
      console.error('Add category failed', error);
      throw error.response?.data || 'Add category failed';
    }
  };
  
  export const updateProductApi = async (updatedProduct: Product, apiUrl: string): Promise<Product> => {
    try {
      const response: AxiosResponse<Product> = await axiosInstance.put<Product>(`${apiUrl}/${updatedProduct.id}`, updatedProduct);
      return response.data;
    } catch (error:any) {
      console.error('Update category failed', error);
      throw error.response?.data || 'Update category failed';
    }
  };
  
  export const deleteProductApi = async (productId: number, apiUrl: string): Promise<void> => {
    try {
      await axiosInstance.delete<void>(`${apiUrl}/${productId}`);
    } catch (error:any) {
      console.error('Delete product failed', error);
      throw error.response?.data || 'Delete product failed';
    }
  };
import styled, { css } from 'styled-components';
import { media, LAYOUT } from '../../../../styles/constants';
import { BaseCol } from '../../../common/BaseCol/BaseCol';
import { BaseLayout } from '../../../common/BaseLayout/BaseLayout';

interface HeaderInput {
  $isTwoColumnsLayoutHeader: boolean;
}

export const Header = styled(BaseLayout.Header)<HeaderInput>`
  line-height: 1.5;
  background-color: #1B1B1E;
  @media only screen and ${media.md} {
    padding: 0;
    background-color: #1B1B1E;
    height: ${LAYOUT.desktop.headerHeight};
  }

  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`;


export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

export const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SiderLogoDiv = styled.div`
  height: ${LAYOUT.mobile.headerHeight};
  padding: ${LAYOUT.mobile.headerPadding};
  display: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and ${media.md} {
    height: ${LAYOUT.desktop.headerHeight};
    padding-top: ${LAYOUT.desktop.paddingVertical};
    padding-bottom: ${LAYOUT.desktop.paddingVertical};
  }
`;


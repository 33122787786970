import * as S from './RestaurantTables.styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'; // Remove unused useEffect
import { Table } from '../../../api/restaurantTable.api';
import { initRequest, setCurrentTable, setPeopleNumber } from '../../../store/slices/ordersSlice';
import { BaseCol } from '../../common/BaseCol/BaseCol';
import { BaseModal } from '../../common/BaseModal/BaseModal';
import { BaseForm } from '../../common/forms/BaseForm/BaseForm';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { RootState } from '../../../store/store';
import { fetchRestaurantTables } from '../../../store/slices/restaurantTablesSlice';
import { BaseImage } from '../../common/BaseImage/BaseImage';
import tableImage from '../../../assets/icons/table.png';
import openTableOmage from '../../../assets/icons/openTables.svg';
import { BASE_COLORS } from '../../../styles/constants';

interface ViewModalProps {
  open: boolean;
  onCancel: () => void;
}

export const RestaurantTables: React.FC<ViewModalProps> = ({ open, onCancel }) => {
  const [peopleModalOpen, setPeopleModalOpen] = useState(false);
  const tables = useSelector((state: RootState) => state.tables.tables);
  const selectedTable = useSelector((state: RootState) => state.order.request.selectedTable);
  const numberOfPeople = useSelector((state: RootState) => state.order.request.personsNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRestaurantTables());
  }, []);

  const handleTableClick = async (table: Table) => {
    if (table.statut === 'ferme') {
      await dispatch(initRequest(table));
      setPeopleModalOpen(true);
    } else {
      onCancel();
    }
  };
  const handlePeopleModalConfirm = () => {
    dispatch(setPeopleNumber(numberOfPeople));
    setPeopleModalOpen(false);
    onCancel();
  };

  const handleConfirm = async () => {
    handlePeopleModalConfirm();
    const currentTable = {
      table: selectedTable,
    };
    dispatch(setCurrentTable(currentTable));
    setPeopleModalOpen(false);
    onCancel();
  };

  return (
    <>
      <BaseModal size="large" cancelText="Annuler" okButtonProps={{ style: { display: 'none' } }} width="90%" title="Tables" open={open} onCancel={onCancel}>
        <BaseCol xl={50}>
          <S.TableContainer>
             {tables.map((table: Table) => (
              <S.TableSquare
              key={table?.numero}
              onClick={() => handleTableClick(table)}
              style={{
                // backgroundColor: table.statut === 'ouvert' ? '#68D6A4' : 'transparent',
                cursor: 'pointer',
                transition: 'opacity 0.3s ease',
              }}
            >
             <BaseImage
                  style={{ width: '4rem' }}
                  src={table.statut === 'ouvert' ? openTableOmage : tableImage}
                  alt={`Table ${table?.numero}`}
                  preview={false}
              />
              <S.TableNumber style={{color : table?.statut === 'ouvert' ? '#68D6A4' : BASE_COLORS.darkGreen ,  alignSelf: 'center' }}>{table?.numero}</S.TableNumber>
            </S.TableSquare>
            ))}
          </S.TableContainer>
        </BaseCol>
      </BaseModal>

       <BaseModal
        title={`Number of People - Table ${selectedTable?.numero}`}
        open={peopleModalOpen}
        onCancel={() => setPeopleModalOpen(false)}
        onOk={handleConfirm}
      >
       <BaseForm layout="vertical">
          <BaseForm.Item label="Number of People">
            <BaseInput
              type="number"
              min={1}
              value={numberOfPeople || ''}
              onChange={(e: any) => dispatch(setPeopleNumber(Number(e.target.value)))}
            />
          </BaseForm.Item>
        </BaseForm>
      </BaseModal>
    </>
  );
};

import React from "react";
import { EChartsOption } from "echarts-for-react";
import { BaseChartProps, BaseChart } from "./BaseChart";

interface PieChartProps extends BaseChartProps {
  option?: EChartsOption;
  data?: any;
  name?: string;
  showLegend?: boolean;
}

export const BarChart: React.FC<PieChartProps> = ({
  option,
  data,
  name,
  showLegend,
  ...props
}) => {
  const barChartData = data
   ? data.map((item:any) => [
      parseInt(item.somme),
      item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name,
    ])
    : [];
  const sortedChartData = barChartData.sort((a:any, b:any) => b[0] - a[0]);
  const limitedChartData = sortedChartData.slice(0, 3).reverse();

  const defaultBarOption = {
    dataset: {
      source: [["amount", "product"], ...limitedChartData],
    },
    grid: { containLabel: true },
    xAxis: { name: "amount" },
    yAxis: { type: "category" },
    series: [
      {
        type: "bar",
        color: "#C47ED8",
        encode: {
          x: "amount",
          y: "product",
        },
      },
    ],
  };
  return (
    <BaseChart
      style={{ marginBottom: "-3rem", marginTop: "-3rem", marginLeft: "-2rem" }}
      width={200}
      height={260}
      {...props}
      option={{ ...defaultBarOption, ...option }}
    />
  );
};

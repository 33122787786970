import React, { useCallback, useEffect, useState } from "react";
import { BaseCard } from "../../../common/BaseCard/BaseCard";
import { BaseChart } from "../../../common/charts/BaseChart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getLastSevenDaysSales } from "../../../../store/slices/dashboardSlice";
import { BASE_COLORS } from "../../../../styles/constants";

interface DataRow {
  Income: number;
  Day: string;
}
interface Props {
    mobile?: boolean;
}

export const SalesChart: React.FC<Props> = ({mobile}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<DataRow[]>([]);
  const lastSevenDays:any = useSelector((state: RootState) => state.dashboardReducer) || {};

  useEffect(()=> {
    dispatch(getLastSevenDaysSales());
  }, []);

  const runAnimation = useCallback(() => {
    const reorderedData = lastSevenDays?.apercuVente?.map((item: any) => {
      const day = Object.keys(item)[0];
      const income = item[day];
      return [income, day];
    });

    const reversedData = reorderedData?.reverse() || [];
    const datasetWithHeader = [["Income", "Day"], ...reversedData];
  
    setData(datasetWithHeader);
  }, [lastSevenDays?.apercuVente]);

  useEffect(() => {
    setTimeout(() => {
      runAnimation();
    }, 200);
  }, [runAnimation]);

  const option = {
    animationDuration: 10000,
    color: BASE_COLORS.yellow,
    dataset: [
      {
        source: data,
      },
    ],
    tooltip: {
      order: 'valueDesc',
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      nameLocation: 'middle',
    },
    yAxis: {
      name: '',
    },
    grid: {
      left: 65,
      right: 50,
      top: 20,
      bottom: 30,
    },
    series: [
      {
        type: 'line',
        showSymbol: false,
        name: 'Income',
        lineStyle: {color: BASE_COLORS.yellow, width: 3},
        encode: {
          x: 'Day',
          y: 'Income',
          label: ['Day', 'Income'],
          itemName: 'Day',
          tooltip: ['Income'],
        },
      },
    ],
  };

  return (
    <BaseCard headStyle={{fontSize: '1.2rem'}} style={{width: mobile? "80vw" : "53vw"}} padding="0 0 1rem" title={"Aperçu des ventes"}>
      <BaseChart option={option} height="15rem" />
    </BaseCard>
  );
};

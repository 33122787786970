import {
    createAction,
    createAsyncThunk,
    createSlice,
  } from "@reduxjs/toolkit";
import { getReportByDayApi } from "../../api/statistics.api";
import moment from 'moment';


  interface reportingState {
    selectedDay: any;
    dataExists: Boolean;
    reportingByRange: {},
    reportingByDay: {
        somme_ventes : {
            nombre_commandes_validees: number;
            somme_ventes: number;
            sommeVentesDetails: [];
        },
        sommeVentesAnnule : {
            nombre_commandes_annulees: number;
            sommeVentesAnnule: number;
            sommeVentesAnnuleDetails: [];
        },
        averageSpendingByDay: {
            averageSpendingByDay: number;
            somme_montants: number;
            nombrePersonnes: number;
        },
        repartitionsVentes: {
            nombre_commandes_carte: number;
            nombre_commandes_cash: number;
            repartitionsVentesDetails : {
              sommeVentesCardDetails: [];
              sommeVentesCashDetails:[];
            };
            sommeTotale: any;
            sommeVentesCard: any;
            sommeVentesCash: any;
        },
        produitsPopualires : [];
        produitsReduits: {
          NbrcommandesReduits: number;
          commandesReduits: [];
          TotalommandesReduits: number;
        }
    };
    loading: "idle" | "pending" | "succeeded" | "failed";
  }

  const todayDate = moment().format("YYYY/MM/DD");
  
  const initialState: reportingState = {
    selectedDay: todayDate,
    dataExists: false,
    reportingByDay: {
        somme_ventes : {
            nombre_commandes_validees: 0,
            somme_ventes: 0,
            sommeVentesDetails: []
        },
        sommeVentesAnnule : {
            nombre_commandes_annulees: 0,
            sommeVentesAnnule: 0,
            sommeVentesAnnuleDetails: []
        },
        averageSpendingByDay: {
            averageSpendingByDay: 0,
            somme_montants: 0,
            nombrePersonnes: 0
        },
        repartitionsVentes: {
            nombre_commandes_cash: 0,
            nombre_commandes_carte: 0,
            sommeVentesCash: 0,
            sommeVentesCard: 0,
            sommeTotale: 0,
            repartitionsVentesDetails : {
                sommeVentesCashDetails:[],
                sommeVentesCardDetails: []
            }
        },
        produitsPopualires : [],
        produitsReduits : {
          NbrcommandesReduits: 0,
          commandesReduits: [],
          TotalommandesReduits: 0
        }
    },
    reportingByRange : {},
    loading: "idle",
  };
  
  export const setDay:any = createAction<any>("reporting/setDay");
  
  export const setDataExists:any = createAction<any>("reporting/setDataExists");
  
  export const getReportByDay: any = createAsyncThunk(
    "stats/reportByDay",
    async (date: string, { dispatch }) => {
        const API_URL = "/rapprtDetaileParDay";
        try {
        const dayReport: any = await getReportByDayApi(API_URL, date);
        return dayReport;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );


  export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setDay, (state, action) => {
            state.selectedDay = action.payload;
          });
          builder.addCase(setDataExists, (state, action) => {
            state.dataExists = action.payload;
          });
      builder
        .addCase(getReportByDay.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getReportByDay.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.reportingByDay = action.payload;
        })
        .addCase(getReportByDay.rejected, (state) => {
          state.loading = "failed";
        });  
    },
  });
  
  export default reportSlice.reducer;
  
import styled from 'styled-components';
import { BaseButton } from '../../common/BaseButton/BaseButton';

export const DiscountList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  margin-top: 1rem;

  /* Add additional styling as needed */
`;

export const DiscountButton = styled(BaseButton)<{ $selected?: boolean }>`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: ${(props) => (props.$selected ? 'red' : '#007bff')};
  color: ${(props) => (props.$selected ? 'black' : '#fff')};
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.$selected ? 'black' : '#0056b3')};
    color: ${(props) => (props.$selected ? 'white' : '#fff')};
  }
`;

import React, { useEffect, useRef } from 'react';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { HeaderActionWrapper } from '../../Header.styles';
import { BaseButton } from '../../../common/BaseButton/BaseButton';
import { RequireFullscreen } from '../../../common/RequireFullscreen/RequireFullscreen';
import { BASE_COLORS } from '../../../../styles/constants';

interface HeaderProps {
  isPOS?: boolean;
}

export const HeaderFullscreen: React.FC<HeaderProps> = ({isPOS}) => {
  const rootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    rootRef.current = document.getElementById('root');
  }, []);

  return (
    <RequireFullscreen component={rootRef}>
      {(isFullscreen) => (
        <HeaderActionWrapper>
          <BaseButton
            type={isFullscreen ? 'default' : 'text'}
            icon={isFullscreen ? <FullscreenExitOutlined height={25}/> : <FullscreenOutlined style={{color: isPOS ? BASE_COLORS.white : ""}} height={50}/>}
          />
        </HeaderActionWrapper>
      )}
    </RequireFullscreen>
  );
};

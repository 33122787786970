export const ClientReceipt = async (order: any, type?: string) => {
  const itemHeight = 200; // Adjust the item height as needed
  const additionalDetailsHeight = 250; // Adjust the additional details height as needed
  const minHeight = 500; // Minimum height for the receipt

  const itemsHeight = order.items?.length * itemHeight || 1000;
  const dynamicHeight = itemsHeight + additionalDetailsHeight;
  console.log(itemHeight + "px");

  const receiptContent = `
    <div style="text-align: center;">
      <h2>THE BREAKFAST CLUB</h2>
      <p>Address Here</p>
      <hr/>
      <table style="width:100%">
        <tr>
          <th style="text-align: left;">ARTICLE</th>
          <th style="text-align: center;">QTE</th>
          <th style="text-align: right;">PRIX</th>
        </tr>
        ${order.items?.map((item:any) => `
          <tr>
            <td style="text-align: left;">${item.nom}</td>
            <td style="text-align: center;">${item.quantite}</td>
            <td style="text-align: right;">${item.prix.toFixed(2)} DH</td>
          </tr>
        `).join('')}
        ${order.discount ? `<tr><td colspan="2" style="text-align: right;">REDUCTION</td><td style="text-align: right;">${order.discount} %</td></tr>` : ''}
        <tr><td colspan="2" style="text-align: right;">TOTAL</td><td style="text-align: right;">${order.total} DH</td></tr>
      </table>
      ${type ? `<p>PAIEMENT PAR: ${type}</p>` : ''}
      <p>${new Date().toLocaleDateString()}   ${new Date().toLocaleTimeString()}</p>
      <p>ID DE LA TRANSACTION: ${order.numCommande}</p>
      <p>Merci pour votre visite</p>
    </div>
  `;

  // Print the receipt content
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  const printDocument:any = iframe.contentWindow || iframe.contentDocument;
  printDocument.document.open();
  printDocument.document.write(receiptContent);
  printDocument.document.close();
  // printDocument.document.querySelector('div').style.height = `${dynamicHeight}px`;
  // printDocument.document.querySelector('div').style.width = `150px`;

  // Set page size for printing
  const printSettings = `
    <html>
      <head>
        <style>
          @page {
            size: 300px ${dynamicHeight}px;
          }
        </style>
      </head>
      <body>
        <div>${receiptContent}</div>
      </body>
    </html>
  `;
  printDocument.document.write(printSettings);
  printDocument.document.close();

  printDocument.focus();
  printDocument.print();
};

import styled from 'styled-components';
import { BaseCard } from '../../../../common/BaseCard/BaseCard';
import { BaseSpace } from '../../../../common/BaseSpace/BaseSpace';

export const IngredientsCard = styled(BaseCard)`
  line-height: 1;
  overflow: hidden;
  height: 36vh;
`;

export const SingleIngredient = styled.div`

`;

export const IngrediensList = styled(BaseSpace)`

`;
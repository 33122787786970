import React from 'react';
import * as S from './ChartDard.styles';
import { PieChart } from '../../../../../common/charts/PieChart';
import { BarChart } from '../../../../../common/charts/BarChart';
import { BASE_COLORS } from '../../../../../../styles/constants';
import { BaseButton } from '../../../../../common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';

interface StatisticsCardProps {
backgroundColor: string;
  value?: any;
  isPie: boolean;
  title: string;
  popularProducts?: [];
  route?: string;
}

export const ChartCard: React.FC<StatisticsCardProps> = ({popularProducts, route, value, backgroundColor, isPie, title }) => {
  const navigate:any = useNavigate();

  const data = [
    { value: value?.sommeVentesCash, name: "Cash", itemStyle: {color: '#FFEAB3'} },
    { value: value?.sommeVentesCard, name: "Carte", itemStyle: {color: BASE_COLORS.yellow}},
  ];


  const handleClick = () => {
    navigate("/reporting"+ route);
  }

  return (
    <S.StatisticCard headStyle={{color: BASE_COLORS.darkGreen}} title={title} padding={"1rem"} $color={backgroundColor}>
      {isPie ? <PieChart data={data} showLegend={true} />:  <BarChart data={popularProducts} showLegend={true} />}
      <BaseButton
          type="primary"
          style={{
            color: BASE_COLORS.white,
            backgroundColor: BASE_COLORS.lighGreen,
            border: "none",
            borderRadius: "20px",
            width: "4vw",
            height: "3vh",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={handleClick}
        >
          Plus
        </BaseButton>
    </S.StatisticCard>
  );
};

import jsPDF from "jspdf";

export const SendReceipt = (order: any, orderNumber?: number) => {
  const pdfWidth = 80;
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, calculateDynamicHeight(order)],
  });

  // Set font styles
  pdf.setFont("helvetica");
  pdf.setFontSize(8); // Adjust font size as needed

  // Add content to the PDF
  pdf.setFontSize(12); // Increase font size for order number and table number
  pdf.text(`#${orderNumber}`, pdfWidth / 2, 10, { align: "center" });
  pdf.text(`Table: `, pdfWidth / 2 - 10, 15, { align: "center" });
  pdf.setFontSize(14); // Reset to the base font size
  pdf.setFont("helvetica", "bold");
  pdf.text(`${order.selectedTable.numero}`, pdfWidth / 2 + 6, 15, {
    align: "center",
  });
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(12); // Increase font size for order number and table number
  pdf.text(" ", 10, 25); // Add space between table number and date
  pdf.text(
    `${new Date().toLocaleDateString()}     ${new Date().toLocaleTimeString()}`,
    10,
    30
  );
  drawDashedLine(pdf, 10, 35, 70, 35, 0.5, 0.5);

  // Table body
  const startY = 40; // Adjust y-position
  const itemsToUse =
    order.newlyAddedItems && order.newlyAddedItems.length > 0
      ? order.newlyAddedItems
      : order.items;

  itemsToUse?.forEach((item: any, index: any) => {
    pdf.setFont("helvetica", "bold");
    const yPos = startY + index * 15; // Adjust the multiplier to add more space between articles
    pdf.text(`${item.quantite}  ${item.nom}`, 10, yPos);

    // Check if item.description is defined before adding it to the PDF
    if (item.description !== null && item.description !== undefined) {
      // Adjust the yPos + 7 to add more space between article and description
      pdf.setFont("helvetica", "normal");
      pdf.text(`     - ${item.description}`, 10, yPos + 7);
    }
  });
  // Save the PDF

  const pdfDataUri = pdf.output("datauristring");

  // Open a new window with the PDF content
  // Open a new window with the PDF content
  const newWindow = window.open();
  if (newWindow) {
    newWindow.document.write(
      '<iframe src="' +
        pdfDataUri +
        '" style="border:0; width:100%; height:100%;" allowfullscreen></iframe>'
    );
    newWindow.document.addEventListener("DOMContentLoaded", () => {
      // Try to print directly
      newWindow.print();
      // Close the new window after printing
      setTimeout(() => newWindow.close(), 100); // Close the window after 1 second (adjust as needed)
    });
  } else {
    alert("Please allow popups for this website");
  }
};

const calculateDynamicHeight = (order: any) => {
  const pdfMinHeight = 60; // Minimum height
  const itemHeight = 8; // Reduced the height per item
  const itemsToUse =
    order.newlyAddedItems && order.newlyAddedItems.length > 0
      ? order.newlyAddedItems
      : order.items;
  const itemCount = itemsToUse ? itemsToUse.length : 0;
  const calculatedHeight = pdfMinHeight + itemCount * itemHeight + 30; // Adjusted height for additional details

  return calculatedHeight;
};

const drawDashedLine = (
  pdf: jsPDF,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  dashLength: number,
  gapLength: number
) => {
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  const numDashes = Math.floor(
    Math.sqrt(deltaX ** 2 + deltaY ** 2) / (dashLength + gapLength)
  );
  const deltaXPerDash = deltaX / numDashes;
  const deltaYPerDash = deltaY / numDashes;

  for (let i = 0; i < numDashes; i++) {
    if (i % 2 === 0) {
      pdf.line(
        x1 + i * deltaXPerDash,
        y1 + i * deltaYPerDash,
        x1 + (i + 1) * deltaXPerDash,
        y1 + (i + 1) * deltaYPerDash
      );
    }
  }
};

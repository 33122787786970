import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  LoginRequest,
  login,
  SignUpRequest,
  signUp,
  ResetPasswordRequest,
  resetPassword,
  SecurityCodePayload,
  verifySecurityCode,
  NewPasswordData,
  setNewPassword,
} from "../../api/auth.api";
import {
  readToken,
  persistToken,
  deleteToken,
  deleteUser,
} from "../../services/localStorage.service";
import { setUser } from "./userSlice";
import { verifyAdminPINAPI } from "../../api/restaurantTable.api";

interface AuthSlice {
  token: string | null;
  pin: string | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: AuthSlice = {
  token: readToken(),
  pin: null,
  loading: "idle",
};

export const doLogin = createAsyncThunk(
  "auth/doLogin",
  async (
    { loginPayload, apiURL }: { loginPayload: LoginRequest; apiURL: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await login(loginPayload, apiURL);
      dispatch(setUser(res.user));
      persistToken(res.token);
      return res.token;
    } catch (error: any) {
      // Handle login error
      console.error("Login failed", error);
      dispatch(setUser(null));
      deleteToken();
      return rejectWithValue(error.response?.data || "Login failed");
    }
  }
);

export const doSignUp = createAsyncThunk(
  "auth/doSignUp",
  async (signUpPayload: SignUpRequest) => signUp(signUpPayload)
);

export const doResetPassword = createAsyncThunk(
  "auth/doResetPassword",
  async (resetPassPayload: ResetPasswordRequest) =>
    resetPassword(resetPassPayload)
);

export const doVerifySecurityCode = createAsyncThunk(
  "auth/doVerifySecurityCode",
  async (securityCodePayload: SecurityCodePayload) =>
    verifySecurityCode(securityCodePayload)
);

export const doSetNewPassword = createAsyncThunk(
  "auth/doSetNewPassword",
  async (newPasswordData: NewPasswordData) => setNewPassword(newPasswordData)
);

export const verifyAdminPIN: any = createAsyncThunk(
  "order/verifyPin",
  async (pin: number, { rejectWithValue }) => {
    const CHECK_PIN_API = "/checkCodePin";
    try {
      const verified: any = await verifyAdminPINAPI(pin, CHECK_PIN_API);
      return verified.message;
    } catch (error: any) {
      return false;
    }
  }
);

export const setPIN = createAction<string | null>("auth/setPin");

export const doLogout = createAsyncThunk(
  "auth/doLogout",
  async (_, { dispatch }) => {
    deleteToken();
    deleteUser();
    dispatch(setUser(null));
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        state.token = action.payload;
        state.loading = "succeeded";
      })
      .addCase(doLogin.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(doLogout.fulfilled, (state) => {
        state.token = null;
      });
    builder.addCase(setPIN, (state, action) => {
      state.pin = action.payload;
    });
  },
});

export default authSlice.reducer;

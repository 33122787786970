import React from "react";
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from "./styles/GlobalStyle";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import { AppRouter } from "./components/router/AppRouter";
import { useLanguage } from "./hooks/useLanguage";
import deDe from 'antd/lib/locale/de_DE';
import { themeVariables } from "./styles/themeVariables";

const App: React.FC = () => {
  const { language } = useLanguage();

  return (
    <ThemeProvider theme={{ mode: 'light' }}>
    <>
    <meta name="theme-color" content={themeVariables['--primary-color']} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  </ThemeProvider>
  );
};

export default App;

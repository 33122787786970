import axios, { AxiosError, AxiosResponse } from 'axios';
import { readToken } from '../services/localStorage.service';

export interface Ingredient {
  id: number;
  name: string;
  quantity: number;
  limite: any;
  unit: string;
  magasin_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
    baseURL: apiUrl  
});
  
  axiosInstance.interceptors.request.use((config:any) => {
    const token = readToken();
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    return config;
  });
  
  axiosInstance.interceptors.response.use(
    (response:any) => response,
    (error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('HTTP error response:', error.response.data || 'No response data available');
        return Promise.reject(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('HTTP no response:', error.request);
        return Promise.reject({ message: 'No response received from the server' });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('HTTP request setup error:', error.message);
        return Promise.reject({ message: 'Error setting up the request' });
      }
    }
  );

export const fetchIngredientsApi = async (apiUrl: string): Promise<Ingredient[]> => {
    try {
      const response: AxiosResponse<Ingredient[]> = await axiosInstance.get<any>(apiUrl);
      return response.data;
    } catch (error:any) {
      console.error('Fetch categories failed', error);
      throw error.response?.data || 'Fetch categories failed';
    }
  };

  export const addIngredientsApi = async (newIngredient: Ingredient, apiUrl: string): Promise<Ingredient> => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.post<Ingredient>(apiUrl, newIngredient);
      return response.data.category;
    } catch (error:any) {
      console.error('Add category failed', error);
      throw error.response?.data || 'Add category failed';
    }
  };
  
  export const updateIngredientApi = async (updatedIngredient: Ingredient, apiUrl: string): Promise<Ingredient> => {
    try {
      const response: AxiosResponse<Ingredient> = await axiosInstance.put<Ingredient>(`${apiUrl}/${updatedIngredient.id}`, updatedIngredient);
      return response.data;
    } catch (error:any) {
      console.error('Update category failed', error);
      throw error.response?.data || 'Update category failed';
    }
  };
  
  export const deleteIngredientApi = async (IngredientId: number, apiUrl: string): Promise<void> => {
    try {
      await axiosInstance.delete<void>(`${apiUrl}/${IngredientId}`);
    } catch (error:any) {
      console.error('Delete Ingredient failed', error);
      throw error.response?.data || 'Delete Ingredient failed';
    }
  };
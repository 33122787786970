import React from "react";
import * as S from "./Card.styles";
import { useResponsive } from "../../../../../../hooks/useResponsive";
import { BaseCol } from "../../../../../common/BaseCol/BaseCol";
import { BaseRow } from "../../../../../common/BaseRow/BaseRow";
import { Typography } from "antd";
import { BaseButton } from "../../../../../common/BaseButton/BaseButton";
import { useNavigate } from "react-router-dom";
import { BASE_COLORS } from "../../../../../../styles/constants";

interface StatisticsCardProps {
  name: string;
  value: number;
  description: string;
  backgroundColor: string;
  textColor: string;
  titleColor: string;
  buttonColor: string;
  buttonText: string;
  route?: string;
  plus: boolean;
  visitors?: string;
  type: 'repartition' | 'average' | 'total' | 'cancelled' | 'discount';
}

export const NumbersCard: React.FC<StatisticsCardProps> = ({
  buttonColor,
  buttonText,
  titleColor,
  name,
  value,
  description,
  backgroundColor,
  textColor,
  route,
  plus,
  visitors,
  type
}) => {
  const { isTablet: isTabletOrHigher } = useResponsive();
  const navigate:any = useNavigate();

  const handleClick = () => {
    navigate("/reporting"+ route);
  }

  return (
    <S.StatisticCard
      title={name}
      headStyle={{ color: titleColor }}
      padding={"1rem"}
      $color={backgroundColor}
    >
      <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
        <BaseCol style={{ padding: "5vh 0" }} flex={1}>
          <Typography.Text style={{ fontSize: "2vw", color: textColor }}>
            MAD {value}
          </Typography.Text>
          <Typography.Paragraph
            style={{ fontSize: "1rem", marginTop: "1rem", color: textColor }}
          >
            <span style={{color: type === 'cancelled' || type === 'discount' ? BASE_COLORS.red : BASE_COLORS.green , fontWeight: 'bold'}}>{description && description}</span>{visitors && visitors}
          </Typography.Paragraph>
        </BaseCol>
       {plus &&  <BaseButton
          type="primary"
          style={{
            color: buttonText,
            backgroundColor: buttonColor,
            border: "none",
            borderRadius: "20px",
            width: "4vw",
            height: "3vh",
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={handleClick}
        >
          Plus
        </BaseButton>}
      </BaseRow>
    </S.StatisticCard>
  );
};

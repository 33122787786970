import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import {
  Product,
  fetchProductsApi,
  addProductsApi,
  updateProductApi,
  deleteProductApi,
} from "../../api/product.api";

interface ProductsState {
  products: any;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: ProductsState = {
  products: [],
  loading: "idle",
};

const API_URL = "/articles";

export const fetchProducts: any = createAsyncThunk(
  "products/fetchProducts",
  async (categoryId: number | undefined) => {
    const products: any = await fetchProductsApi(categoryId);
    return products;
  }
);

export const addProduct: any = createAsyncThunk(
  "products/addProduct",
  async (newProduct: Product, {dispatch}) => {
     const product = addProductsApi(newProduct, API_URL);
     dispatch(fetchProducts(newProduct.category_id));
     return product;
  }
);

export const updateProduct:any = createAsyncThunk(
  "products/updateProduct",
  async (updatedProduct: Product, { dispatch }) => {
    const product = updateProductApi(updatedProduct, API_URL);
    dispatch(fetchProducts(updateProduct.category_id));
     return product;
    
  }
);

export const deleteProduct:any = createAsyncThunk(
  "products/deleteProduct",
  async (product: any, { dispatch }) => {
    const productDeleted =  deleteProductApi(product?.id, API_URL);
    dispatch(fetchProducts(product?.category_id));
    return productDeleted;
  }
);

const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(addProduct.fulfilled, (state: any, action) => {
        const newProduct: Product = action.payload;
        state.products = [...state.products, newProduct];
      })
      .addCase(addProduct.rejected, (state) => {});
  },
});

export default ProductsSlice.reducer;

import React from 'react';
import * as S from './Card.styles';
import { useResponsive } from '../../../../../hooks/useResponsive';
import { BaseCol } from '../../../../common/BaseCol/BaseCol';
import { BaseRow } from '../../../../common/BaseRow/BaseRow';
import { Typography } from 'antd';
import { BaseSpace } from '../../../../common/BaseSpace/BaseSpace';
import { BASE_COLORS } from '../../../../../styles/constants';

interface StatisticsCardProps {
  name: string;
  value: any;
  description: string;
  backgroundColor: string;
  textColor: string;
  type: 'average' | 'canceled' | 'total';
}

export const NumbersCard: React.FC<StatisticsCardProps> = ({ name, value, description, backgroundColor, textColor, type }) => {
  const { isTablet: isTabletOrHigher } = useResponsive();

  return (
    <S.StatisticCard padding={"1rem"} $color={backgroundColor}>
      <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
        <BaseCol flex={1}>
          <BaseRow justify="space-between" align="middle" wrap={false}>
            <BaseSpace direction='vertical' size={40}>
            <Typography.Text style={{ color: textColor, fontSize: '1.2rem'}}>
                {name}
              </Typography.Text>
              <div>
              <Typography.Text style={{fontSize: "2vw", color: textColor}} >
                MAD {value}
              </Typography.Text>
              {type === 'average' ? <Typography.Paragraph style={{fontSize: "0.8vw", marginTop: "0.5rem", color: textColor}}>
                <span style={{color: BASE_COLORS.green}}>{description}</span> personnes visitées
              </Typography.Paragraph> : type === 'canceled' ? <Typography.Paragraph style={{fontSize: "0.8vw", marginTop: "0.5rem", color: textColor}}>
                <span style={{color: BASE_COLORS.red}}>{description}</span> commandes annulées
              </Typography.Paragraph> : <Typography.Paragraph style={{fontSize: "0.8vw", marginTop: "0.5rem", color: textColor}}>
                {description}
              </Typography.Paragraph>}
              </div>
            </BaseSpace>
            
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </S.StatisticCard>
  );
};

import styled, { css } from 'styled-components';
import { media, LAYOUT } from '../../../../styles/constants';
import { BaseCol } from '../../../common/BaseCol/BaseCol';
import { BaseLayout } from '../../../common/BaseLayout/BaseLayout';
import { BaseRow } from '../../../common/BaseRow/BaseRow';
import { BaseInput } from '../../../common/inputs/BaseInput/BaseInput';


export const LayoutMaster = styled(BaseLayout)`
  
`;


export const Categories = styled(BaseRow)`
    display: flex;
    width: 100vh;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
`;

export const SearchBox = styled.input`
  width: 200px; /* Adjust the width as needed */
  padding: 0.5rem;
  border: none;
  background-color: #152b35;
  color: #fff;
`;

export const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableSlider = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis; 
`;

export const TableBox = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e6f7ff; /* Highlight color on hover */
  }
}`;

export const TableBoxSelected = `
  background-color: #1890ff; /* Selected table color */
  color: #fff; /* Text color for selected table */
  `;

  export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

interface ProfileColumninput {
  $isTwoColumnsLayout: boolean;
};

export const ProfileColumn = styled(BaseCol)<ProfileColumninput>`
  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: #3d4151;
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const Amount = styled(BaseCol)`
  background-color: #3d4151;
`;

export const Tables = styled.div`
  height: 40px;
  width: 70px;
  background-color: #535761;
  text-align: center;
`;

export const TablesList = styled.div`
  height: 40px;
  width: 40px;
  background-color: #343945;
  text-align: center;
  align-items: center;
  border: 1px solid black;
  flex: 0 0 auto;
`;

export const Div = styled(BaseRow)`
  display: flex;
  width: 25vh;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll; 
`;


export const Container = styled.div`
    margin: 0 auto;
    width: 50vh;
    align-items: center;
    text-align: center;
    border-radius: 8px;
`;


export const Keypad = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-auto-rows: minmax(60px, auto);
    grid-gap: 8px;
`;

export const Input = styled(BaseInput)`
    height: 70px;
    width: 292px;
    background-color: black;
    color: white;
    text-align: right;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
`;
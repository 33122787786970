import React, { useEffect } from 'react';
import * as S from '../Header.styles';
import { BaseCol } from '../../common/BaseCol/BaseCol';
import { BaseRow } from '../../common/BaseRow/BaseRow';
import moment from 'moment';
import 'moment/locale/fr';
import { useTitle } from '../../../contexts/TitleContext';
import { BaseTypography } from '../../common/BaseTypography/BaseTypography';
import { useLocation } from 'react-router-dom';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {

  let currentDate = moment().locale('fr').format('MMMM Do YYYY, hh:mm A');
  const { title, setTitle } = useTitle();
  const location = useLocation();

  useEffect(() => {
    // Set the title when the route changes
    if (location.pathname === '/') {
      setTitle('Bienvenue dans votre tableau de bord !');
    } else if (location.pathname === '/reporting') {
      setTitle('Bienvenue dans vos rapports détaillés !');
    } else if (location.pathname === '/menu') {
      setTitle('Gestion des menus');
    } else if(location.pathname === '/accounting'){
      setTitle('Bienvenue dans votre comptabilité');
    } else if(location.pathname === '/suppliers'){
      setTitle('Bienvenue dans votre platforme fournisseurs');
    }
  }, [location.pathname, setTitle]);
  
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
       <BaseTypography.Title style={{fontSize: "1.5rem"}}>{title}</BaseTypography.Title>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
       <BaseTypography.Title style={{marginTop: '-1rem', fontSize: "1.5rem"}}>{title}</BaseTypography.Title>
      </BaseRow>
    </S.SearchColumn>
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn  xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol xl={10}>
            {currentDate}
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ReportingPage.styles";
import { BaseRow } from "../../components/common/BaseRow/BaseRow";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import moment from "moment";
import { DatePicker } from "antd";
import ReportingCard from "../../components/reporting/components/EmptyReport/emptyReporting";
import GeneralReport from "../../components/reporting/components/GeneralReport/GeneralReporting";
import { getReportByDay, setDataExists, setDay } from "../../store/slices/reportingSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

const ReportingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();
  const dataExists = useSelector((state: RootState) => state.reporting.dataExists);
  const selectedDate = useSelector((state: RootState) => state.reporting.selectedDay);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedDate) {
        try {
          const response = await dispatch(getReportByDay(selectedDate));
          console.log(response.payload);
  
          if (response.payload && response.payload.message !== null) {
            dispatch(setDataExists(true));
          } else {
            dispatch(setDataExists(false));
            console.log('Setting dataExists to false');
          }
        } catch (error) {
          console.error('Error fetching report by day:', error);
          setDataExists(false);
        }
      }
    };
    fetchData();
  }, [dispatch, selectedDate]);

  const desktopLayout = (
    <S.Main>
      <BaseRow>
       
        <DatePicker
          style={{ height: "6vh" }}
          value={selectedDate ? moment(selectedDate) : null}
          onChange={async(date: any) => {
            if (date) {
              console.log(moment(date).format("YYYY/MM/DD"));
              dispatch(setDay(moment(date).format("YYYY/MM/DD")));
            } 
          }}
        />
        
      </BaseRow>
      {dataExists ? (
        <GeneralReport />
      ) : (
        <ReportingCard />
      )}
    </S.Main>
  );

  const mobileAndTabletLayout = (
    <S.Main>
      <BaseRow>
        
        <DatePicker
          style={{ height: "6vh" }}
          value={selectedDate ? moment(selectedDate) : null}
          onChange={(date: any) => {
            if (date) {
              console.log(moment(date).format("YYYY/MM/DD"));
              dispatch(setDay(moment(date).format("YYYY/MM/DD")));
            }
          }}
        />
       
      </BaseRow>
      {dataExists ? (
        <GeneralReport />
      ) : (
        <ReportingCard />
      )}
    </S.Main>
  );

  return (
    <>
      <PageTitle>{t("common.menu.reporting")}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ReportingPage;

import styled from 'styled-components';
import { media } from '../../../styles/constants';
import { BaseCol } from '../../common/BaseCol/BaseCol';
import { BaseRow } from '../../common/BaseRow/BaseRow';

export const ProfileColumn = styled(BaseCol)`
  @media only screen and ${media.md} {
    background-color: #3d4151;
  }
`;

export const Tables = styled.div`
  height: 40px;
  width: 70px;
  background-color: #535761;
  text-align: center;
`;

export const TablesList = styled.div`
  height: 40px;
  width: 60px;
  background-color: #343945;
  text-align: center;
  align-items: center;
  border: 1px solid black;
  padding: 0.5rem;
  flex: 0 0 auto;
`;

export const Div = styled(BaseRow)`
  display: flex;
  width: 14rem;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll; 
`;
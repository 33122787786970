import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Category, fetchCategoriesApi, addCategoryApi, updateCategoryApi, deleteCategoryApi } from '../../api/category.api';

interface CategoriesState {
  categories: { categories: [], magasin: number};
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: CategoriesState = {
  categories: { categories: [], magasin: 0},
  loading: 'idle',
};


const API_URL = '/categories';

export const getCategories: any = createAsyncThunk(
  "categories/getCategories",
  async () => {
    const categories: any = await fetchCategoriesApi("/categories");
    return categories;
  }
);

export const addCategory:any = createAsyncThunk('categories/addCategory', async (newCategory: Category, {dispatch}) => {
  const category: any = await addCategoryApi(newCategory, API_URL);
  dispatch(getCategories());
  return category;
});

export const updateCategory:any = createAsyncThunk('categories/updateCategory', async (updatedCategory: Category, { dispatch}) => {
  const updatedCategories: any = await updateCategoryApi(updatedCategory, API_URL);
  dispatch(getCategories());
    return updatedCategories;
});

export const deleteCategory:any = createAsyncThunk('categories/deleteCategory', async (categoryId: number, { dispatch}) => {
  const updatedCategories: any = await deleteCategoryApi(categoryId, API_URL);;
  dispatch(getCategories());
    return updatedCategories;
});

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(addCategory.fulfilled, (state:any, action) => {
        const newCategory:Category = action.payload;
        state.categories = [...state.categories.categories, newCategory];
      })
      .addCase(addCategory.rejected, (state) => {
        // Handle category creation failure if needed
      });
  },
});

export default categoriesSlice.reducer;

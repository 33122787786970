import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LoginPage from '../../pages/LoginPage';
import MainLayout from '../layouts/main/MainLayout/MainLayout';
import POSLayout from '../layouts/main/POSLayout/POSLayout';
import RequireAuth from '../router/RequireAuth';
import { withLoading } from '../../hocs/withLoading.hoc';
import DashboardPage from '../../pages/DashboardPages/DashboardPage';
import ReportingPage from '../../pages/ReportingPage/ReportingPage';
const AuthLayout = React.lazy(() => import('../layouts/AuthLayout/AuthLayout'));

const ServerErrorPage = React.lazy(() => import('../../pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('../../pages/Error404Page'));

const MenuPage = React.lazy(() => import('../../pages/MenuPage'));
const InventoryPage = React.lazy(() => import('../../pages/InventoryPage'));
const POSPage = React.lazy(() => import('../../pages/POSpage/POSPage'));
const RestaurantTablesPage = React.lazy(() => import('../../pages/RestaurantTablesPage'));
const ChooseUserPage = React.lazy(() => import('../../pages/ChooseUserPage'));
const TeamPage = React.lazy(() => import('../../pages/TeamPage'));
const Logout = React.lazy(() => import('./Logout'));
const TotalSalesPage = React.lazy(() => import('../reporting/components/DetailedReports/TotalSales/TotalSales'));
const SalesRepartitionPage = React.lazy(() => import('../reporting/components/DetailedReports/SalesRepartition/SalesRepartition'));
const PopularProductsPage = React.lazy(() => import('../reporting/components/DetailedReports/PopularProducts/PopularProducts'));
const CanceledSalesPage = React.lazy(() => import('../reporting/components/DetailedReports/CanceledSales/CanceledSales'));
const DiscountsPage = React.lazy(() => import('../reporting/components/DetailedReports/Discounts/Discounts'));
const AccountingPage = React.lazy(() => import('../accounting//Accounting'));
const SuppliersPage = React.lazy(() => import('../suppliers/Suppliers'));

export const DASHBOARD_PATH = '/';
export const REPORTING_PATH = '/reporting';

const Reporting = withLoading(ReportingPage);
const Dashboard = withLoading(DashboardPage);

// UI Components
const Menu = withLoading(MenuPage);
const Inventory = withLoading(InventoryPage);
const POS = withLoading(POSPage);
const RestaurantTables = withLoading(RestaurantTablesPage);
const ChooseUser = withLoading(ChooseUserPage);
const Team = withLoading(TeamPage);
const TotalSales = withLoading(TotalSalesPage);
const SalesRepartition = withLoading(SalesRepartitionPage);
const PopularProducts = withLoading(PopularProductsPage);
const CanceledSales = withLoading(CanceledSalesPage);
const Discounts = withLoading(DiscountsPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
const Suppliers = withLoading(SuppliersPage);
const Accounting = withLoading(AccountingPage);

// Profile

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );


  const chooseUserLayout = (
    <RequireAuth>
      <ChooseUser />
    </RequireAuth>
  );

  const POSProtectedLayout = (
    <RequireAuth>
      <POSLayout  />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={REPORTING_PATH} >
            <Route path={''} element={<Reporting />} />
            <Route path={'total-sales'} element={<TotalSales />} />
            <Route path={'sales-repartition'} element={<SalesRepartition />} />
            <Route path={'popular-products'} element={<PopularProducts />} />
            <Route path={'canceled-sales'} element={<CanceledSales />} />
            <Route path={'discounts'} element={<Discounts />} />
          </Route>
          <Route path={'inventory'} element={<Inventory />} />
          <Route path={'menu'} element={<Menu />} />
          <Route path={'planning'} element={<RestaurantTables />} />
          <Route path={'team'} element={<Team />} />
          <Route path={'accounting'} element={<Accounting />} />
          <Route path={'suppliers'} element={<Suppliers />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          
        </Route>
        <Route path={'/'} element={POSProtectedLayout}>
          <Route path={'POS'} element={<POS />} />
        </Route>
        <Route path={'/'} element={chooseUserLayout}>
          <Route path={'choose'} element={<ChooseUser />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};

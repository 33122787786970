import styled from 'styled-components';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import { media } from '../../../styles/constants';

export const Container = styled.div`
    margin: 0 auto;
   
    align-items: center;
    text-align: center;
    border-radius: 8px;
`;

export const CButton = styled(BaseButton)`
    width: 8vw;
    height: 6.2vh;
    font-size: 1.2vw;
    @media screen and (min-width: ${media.md}) and (max-width: ${media.lg}) {
        width: 5vw;
        height: 7vh;
        font-size: 12px;
    }
`;

export const Keypad = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-auto-rows: minmax(60px, auto);
    grid-gap: 8px;
`;

export const Input = styled(BaseInput)`
    height: 70px;
    width: 292px;
    background-color: black;
    color: white;
    text-align: right;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
`;
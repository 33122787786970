import { UserModel } from '../domain/UserModel';
import { readToken } from '../services/localStorage.service';
import axios, { AxiosError } from 'axios';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  name: string;
  email: string;
  phone: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config:any) => {
  config.headers = { ...config.headers, Authorization: `${readToken()}` };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('HTTP error response:', error.response.data || 'No response data available');
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('HTTP no response:', error.request);
      return Promise.reject({ message: 'No response received from the server' });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('HTTP request setup error:', error.message);
      return Promise.reject({ message: 'Error setting up the request' });
    }
  }
);

// Update the login function to accept the API URL as a parameter
export const login = async (loginPayload: LoginRequest, apiUrl: string): Promise<LoginResponse> =>
  await axiosInstance.post<LoginResponse>(apiUrl, { ...loginPayload })
    .then(({ data }) => data)
    .catch((error) => {
      console.error('Login failed', error);
      throw error.response?.data || 'Login failed';
    });

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
axiosInstance.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
axiosInstance.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
axiosInstance.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }:any) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
axiosInstance.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Ingredient, fetchIngredientsApi, addIngredientsApi, updateIngredientApi, deleteIngredientApi } from '../../api/ingredients.api';

interface IngredientsState {
  ingredients: any;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: IngredientsState = {
  ingredients: [],
  loading: 'idle',
};


const API_URL = '/ingredients';

export const fetchIngredients:any = createAsyncThunk('Ingredients/fetchIngredients', async () => {
  const ingredients:any = await fetchIngredientsApi(API_URL);
  return ingredients;
});

export const addIngredient:any = createAsyncThunk('Ingredients/addIngredient', async (newIngredient: Ingredient, { dispatch }) => {
  const ingredient = addIngredientsApi(newIngredient, API_URL);
  dispatch(fetchIngredients());
  return ingredient;
});

export const updateIngredient:any = createAsyncThunk('Ingredients/updateIngredient', async (updatedIngredient: Ingredient, { dispatch }) => {
  const ingredient = updateIngredientApi(updatedIngredient, API_URL);
  dispatch(fetchIngredients());
  return ingredient;
});

export const deleteIngredient:any = createAsyncThunk('Ingredients/deleteIngredient', async (IngredientId: number, { dispatch }) => {
  const ingredient = deleteIngredientApi(IngredientId, API_URL);
  dispatch(fetchIngredients());
  return ingredient;
});

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIngredients.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchIngredients.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.ingredients = action.payload;
      })
      .addCase(fetchIngredients.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(addIngredient.fulfilled, (state, action) => {
        const newIngredient: Ingredient = action.payload;
        state.ingredients = [...state.ingredients?.ingredients, newIngredient];
      })
      .addCase(addIngredient.rejected, (state) => {
      });
  },
});

export default ingredientsSlice.reducer;

import styled from 'styled-components';
import { BaseCard } from '../../../../common/BaseCard/BaseCard';

interface StatisticsProps {
    $color: string;
  }
  

export const StatisticCard = styled(BaseCard)<StatisticsProps>`
  line-height: 1;
  overflow: hidden;
  background-color: ${(props) => `${props.$color}`};;
`;

import * as S from "./Discount.styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  calculateTotalPrice,
  setDiscount,
  setDiscountDB,
  setDiscountReason,
  verifyAdminPIN,
} from "../../../store/slices/ordersSlice";
import { BaseModal } from "../../common/BaseModal/BaseModal";
import { BaseInput } from "../../common/inputs/BaseInput/BaseInput";
import { RootState } from "../../../store/store";
import { notificationController } from "../../../controllers/notificationController";
import TextArea from "antd/lib/input/TextArea";

interface DiscountModalProps {
  open: boolean;
  onCancel: () => void;
}

export const Discount: React.FC<DiscountModalProps> = ({ open, onCancel }) => {
  const dispatch = useDispatch();
  const [, setDiscountModalOpen] = useState(false);
  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [confirmedDiscount, setConfirmedDiscount] = useState<number | null>(null);
  const selectedTable: any = useSelector((state: RootState) => state.order.request.selectedTable);
  const discount: number = useSelector(
    (state: RootState) => state.order.request.discount
  );

  const [enteredPIN, setEnteredPIN] = useState("");
  const discountOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const handleDiscountSelect = async(selectedDiscount: number) => {
    if (confirmedDiscount === selectedDiscount) {
      setConfirmedDiscount(null);
      dispatch(setDiscount(0));
      setPinModalOpen(false);
          onCancel();
    } else {
      const data = {
        id: selectedTable?.id,
        raison: description,
        reduction: selectedDiscount,
      }
      setConfirmedDiscount(selectedDiscount);
      try {
        await dispatch(setDiscountDB(data));
        setEnteredPIN("");
        setDescription("");
        setPinModalOpen(true);
      } catch (error) {
        console.error("Error setting discount:", error);
      }
    }
  };

  const onPinModalCancel = () => {
    setPinModalOpen(false);
    setDiscountModalOpen(false);
  };

  const onOk = async () => {
    onCancel();
    setPinModalOpen(false);
    setDiscountModalOpen(false);
  };

  const onPinEntered = async (enteredPIN: string) => {
    await dispatch(verifyAdminPIN(enteredPIN))
      .then((verified: any) => {
        if (verified.payload) {
          if (confirmedDiscount !== null) {
            dispatch(setDiscount(confirmedDiscount));
            dispatch(setDiscountReason(description));
            dispatch(calculateTotalPrice());
          }
          setPinModalOpen(false);
          onCancel();
        } else {
          notificationController.error({ message: "Invalid PIN" });
        }
      })
      .catch((err: any) => {
        // Handle other errors
        notificationController.error({ message: err.message });
      });
  };

  return (
    <>
      <BaseModal
        size="medium"
        title="Discount"
        open={open}
        cancelText='Annuler'
        onCancel={onCancel}
        onOk={onOk}
      >
        <TextArea
          placeholder="Raison de cette réduction"
          rows={2}
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <S.DiscountList>
          {discountOptions.map((discountOption, index) => (
            <S.DiscountButton
              key={index}
              noStyle={true}
              onClick={() => handleDiscountSelect(discountOption)}
              $selected={discountOption === discount}
            >
              {discountOption}%
            </S.DiscountButton>
          ))}
        </S.DiscountList>
      </BaseModal>
      <BaseModal
        onOk={() => onPinEntered(enteredPIN)}
        size="small"
        title="Enter votre PIN"
        open={pinModalOpen}
        onCancel={() => onPinModalCancel()}
      >
        <BaseInput
          placeholder="Entrer votre PIN"
          type="password"
          value={enteredPIN}
          onChange={(e) => setEnteredPIN(e.target.value)}
        />
      </BaseModal>
    </>
  );
};

import {
    createAsyncThunk,
    createSlice,
  } from "@reduxjs/toolkit";
import { generateXReportApi, generateZReportApi, getAverageSpendingApi, getCanceledSalesSumApi, getCardOrCashSumApi, getLastSevenDaysApi, getLowStockApi, getPopularArticlesApi, getPopularArticlesbyDayApi, getSalesSumApi } from "../../api/statistics.api";

  interface dashboardState {
    xReport: any;
    zReport: any;
    somme_ventes: {};
    sommeVentesAnnule: {};
    lastSevenDays: {apercuVente : []};
    Depense_moyenne_par_personnes: number;
    lowStock: any;
    cardOrCashSum: { sommeVenteCash: number; sommeVenteCard: number };
    popularArticles: { produitsPopulaires: []};
    popularArticlesbyDay: any;
    loading: "idle" | "pending" | "succeeded" | "failed";
  }
  
  const initialState: dashboardState = {
    xReport: {},
    lastSevenDays: { apercuVente: [] },
    zReport: {},
    somme_ventes: {},
    sommeVentesAnnule: {},
    Depense_moyenne_par_personnes: 0,
    lowStock: null,
    cardOrCashSum: { sommeVenteCard: 0, sommeVenteCash: 0},
    popularArticles: { produitsPopulaires: []},
    popularArticlesbyDay: null,
    loading: "idle",
  };
  
  
  export const getXReport: any = createAsyncThunk(
    "stats/Xreport",
    async () => {
        const API_URL = "/statistiquesRapportX";
      try {
        const Xreport: any = await generateXReportApi(API_URL);
        return Xreport;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );

  export const getZReport: any = createAsyncThunk(
    "stats/Zreport",
    async () => {
        const API_URL = "/statistiquesRapportY";
      try {
        const zReport: any = await generateZReportApi(API_URL);
        return zReport;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );

  export const getSalesSum: any = createAsyncThunk(
    "stats/salesSum",
    async () => {
        const API_URL = "/sommeVentes";
      try {
        const sales: any = await getSalesSumApi(API_URL);
        return sales;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );
  
  export const getCanceledSalesSum: any = createAsyncThunk(
    "stats/canceledSalesSum",
    async () => {
        const API_URL = "/sommeVentesAnnule";
      try {
        const canceled: any = await getCanceledSalesSumApi(API_URL);
        return canceled;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );
  export const getAverageSpending: any = createAsyncThunk(
    "stats/averageSpending",
    async () => {
        const API_URL = "/depenseMoyenne";
      try {
        const average: any = await getAverageSpendingApi(API_URL);
        return average;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );
  
  export const getLowStock: any = createAsyncThunk(
    "stats/lowStock",
    async () => {
        const API_URL = "/stockFaible";
      try {
        const zReport: any = await getLowStockApi(API_URL);
        return zReport;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );

  export const getCardOrCashSum: any = createAsyncThunk(
    "stats/cardOrCashSum",
    async () => {
        const API_URL = "/repartionsVentes";
      try {
        const zReport: any = await getCardOrCashSumApi(API_URL);
        return zReport;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );

  export const getPopularArticles: any = createAsyncThunk(
    "stats/popularArticles",
    async () => {
        const API_URL = "/produitsPopulaires";
      try {
        const popularArticles: any = await getPopularArticlesApi(API_URL);
        return popularArticles;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );

   export const getPopularArticlesByDay: any = createAsyncThunk(
    "stats/popularArticlesByDay",
    async () => {
        const API_URL = "/produitsPopulairesParDay";
      try {
        const popularArticles: any = await getPopularArticlesbyDayApi(API_URL);
        return popularArticles;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );

  export const getLastSevenDaysSales: any = createAsyncThunk(
    "stats/lastSevenDaysSales",
    async () => {
        const API_URL = "/apercuVenteslast7day";
      try {
        const lastSevenDays: any = await getLastSevenDaysApi(API_URL);
        return lastSevenDays;
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    }
  );
  

  export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getXReport.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getXReport.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.xReport = action.payload;
        })
        .addCase(getXReport.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getZReport.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getZReport.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.zReport = action.payload;
        })
        .addCase(getZReport.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getSalesSum.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getSalesSum.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.somme_ventes = action.payload;
        })
        .addCase(getSalesSum.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getCanceledSalesSum.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getCanceledSalesSum.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.sommeVentesAnnule = action.payload;
        })
        .addCase(getCanceledSalesSum.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getAverageSpending.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getAverageSpending.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.Depense_moyenne_par_personnes = action.payload;
        })
        .addCase(getAverageSpending.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getLowStock.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getLowStock.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.lowStock = action.payload;
        })
        .addCase(getLowStock.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getCardOrCashSum.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getCardOrCashSum.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.cardOrCashSum = action.payload;
        })
        .addCase(getCardOrCashSum.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getPopularArticles.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getPopularArticles.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.popularArticles = action.payload;
        })
        .addCase(getPopularArticles.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getPopularArticlesByDay.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getPopularArticlesByDay.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.popularArticlesbyDay = action.payload;
        })
        .addCase(getPopularArticlesByDay.rejected, (state) => {
          state.loading = "failed";
        });
      builder
        .addCase(getLastSevenDaysSales.pending, (state) => {
          state.loading = "pending";
        })
        .addCase(getLastSevenDaysSales.fulfilled, (state, action) => {
          state.loading = "succeeded";
          state.lastSevenDays = action.payload;
        })
        .addCase(getLastSevenDaysSales.rejected, (state) => {
          state.loading = "failed";
        });
    },
  });
  
  export default reportSlice.reducer;
  
import React, { useEffect } from "react";
import * as S from "./LowStock.styles";
import { RootState } from "../../../../../store/store";
import { BaseCol } from "../../../../common/BaseCol/BaseCol";
import { BaseRow } from "../../../../common/BaseRow/BaseRow";
import { Divider, Typography } from "antd";
import { BaseSpace } from "../../../../common/BaseSpace/BaseSpace";
import { BASE_COLORS } from "../../../../../styles/constants";
import { getLowStock } from "../../../../../store/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const LowStock: React.FC = () => {
  const dispatch = useDispatch();

  const {
    lowStock,
  } = useSelector((state: RootState) => state.dashboardReducer);
  useEffect(() => {
    dispatch(getLowStock());
  }, []);

  return (
    <S.IngredientsCard padding={"1rem"}>
      <BaseCol>
        <BaseRow justify={"space-between"}>
          <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
            Stock Faibles
          </Typography.Text>
          <Link to='/inventory'><Typography.Text
            style={{ color: BASE_COLORS.lighGreen, cursor: "pointer" }}
          >
            Voir Tout
          </Typography.Text>
          </Link>
        </BaseRow>
        <BaseSpace direction="vertical" style={{ marginTop: "2rem" }} size={40}>
          <S.IngrediensList direction="vertical" size={0}>
            {lowStock?.ingredients_stock_faible?.map((ingredient: any) => (
              <>
                <BaseSpace direction="vertical" size={3}>
                  <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
                    {ingredient.name}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      color: BASE_COLORS.lighGreen,
                      fontSize: "0.7vw",
                    }}
                  >
                    Disponible: <span style={{color: BASE_COLORS.red}}>{ingredient.quantity} {ingredient.unit}</span>
                  </Typography.Text>
                </BaseSpace>
                <Divider
                  orientation="center"
                  style={{ backgroundColor: "#F0F0EC", width: "18vw" }}
                />
              </>
            ))}
          </S.IngrediensList>
        </BaseSpace>
      </BaseCol>
    </S.IngredientsCard>
  );
};

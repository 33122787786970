import React, { useEffect, useState } from "react";
import MainSider from "../sider/MainSider/MainSider";
import MainContent from "../MainContent/MainContent";
import { MainHeader } from "../MainHeader/MainHeader";
import * as S from "./MainLayout.styles";
import { Outlet, useLocation } from "react-router-dom";
import { useResponsive } from "../../../../hooks/useResponsive";
import { REPORTING_PATH, DASHBOARD_PATH } from "../../../router/AppRouter";
import { Header } from "../../../header/Header";
import { References } from "../../../common/References/References";
import { TitleProvider } from "../../../../contexts/TitleContext";

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  
  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout(
      [REPORTING_PATH, DASHBOARD_PATH].includes(location.pathname) && isDesktop
    );
  }, [location.pathname, isDesktop]);

  return (
    <TitleProvider>
      <S.LayoutMaster>
        <MainSider
          isCollapsed={siderCollapsed}
          setCollapsed={setSiderCollapsed}
        />
        <S.LayoutMain>
          <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
            <Header
              toggleSider={toggleSider}
              isSiderOpened={!siderCollapsed}
              isTwoColumnsLayout={isTwoColumnsLayout}
            />
          </MainHeader>
          <MainContent
            id="main-content"
            $isTwoColumnsLayout={isTwoColumnsLayout}
          >
            <div>
              <Outlet />
            </div>
            {!isTwoColumnsLayout && <References />}
          </MainContent>
        </S.LayoutMain>
      </S.LayoutMaster>
    </TitleProvider>
  );
};

export default MainLayout;

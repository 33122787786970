import React, { useCallback, useEffect, useState } from "react";
import * as S from "./ProductsTable.styles";
import { BaseTable } from "../BaseTable/BaseTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBasicTableData, Pagination } from "../../../api/table.api";
import { useMounted } from "../../../hooks/useMounted";
import { RootState } from "../../../store/store";
import { BASE_COLORS } from "../../../styles/constants";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { Table } from "../../../api/restaurantTable.api";
import moment from "moment";
import {
  calculateTotalPrice,
  deleteItem,
  getCurrentTable,
  getOpenTables,
  setSelectedTable,
  updateItem,
} from "../../../store/slices/ordersSlice";
import { BaseModal } from "../../common/BaseModal/BaseModal";
import TextArea from "antd/lib/input/TextArea";

const initialPagination: Pagination = {
  current: 1,
  pageSize: 4,
};

interface SelectedItem {
  id?: number;
  nom: string;
  quantite: number;
  prix_total: number | null;
  description?: string | null;
}

const ProductsTable: React.FC = () => {
  const openTables = useSelector((state: RootState) => state.order.openTables);
  const [descModalOpen, setDescModalOpen] = useState(false);
  const [description, setDescription] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(0);

  const ingredientsColumns = [
    {
      key: "Quantity",
      dataIndex: "quantite",
      title: "Qté",
      width: 50,
    },
    {
      key: "Ingredient",
      dataIndex: "nom",
      title: "Nom",
      render: (text: any, record: any, index: number) => (
        <Typography
          style={{
            color: BASE_COLORS.white,
            fontSize: "18px",
            fontWeight: 500,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </Typography>
      ),
      width: 170,
    },
    {
      key: "Price",
      dataIndex: "prix_total",
      title: "Prix",
      width: 50,
    },
    {
      key: "Action",
      render: (text: any, record: any, index: number) => (
        <BaseButton type="link" onClick={() => {}}>
          <EditFilled onClick={()=>{
            setSelectedArticle(record.article_id);
            setDescModalOpen(true)
          }}/>
          <DeleteFilled onClick={async ()=>{
            await dispatch(deleteItem(record.article_id));
          }}/>
        </BaseButton>
      ),
      title: "Act",
      width: 80,
    },
  ];

  const dispatch = useDispatch();
  const [timeNow, ] = useState<string>(
    moment().format("DD-MM-YYYY hh:mm")
  );
  const selectedTable = useSelector(
    (state: RootState) => state.order.request.selectedTable
  );
  const discount: number = useSelector(
    (state: RootState) => state.order.request.discount
  );
  const selectedItems: SelectedItem[] = useSelector(
    (state: RootState) => state.order.request.items
  );

  const newlyAddedItems: SelectedItem[] = useSelector(
    (state: RootState) => state.order.request.newlyAddedItems
  );
  const totalPrice: number = useSelector(
    (state: RootState) => state.order.request.total
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [selectedItems, newlyAddedItems]);

  useEffect(() => {
    dispatch(getOpenTables());
  }, []);

  const [, setTableData] = useState<{
    data: any[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination) => {
      dispatch(calculateTotalPrice());
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getBasicTableData(selectedItems, pagination).then((res: any) => {
        if (isMounted.current) {
          setTableData({
            data: res.data,
            pagination: res.pagination,
            loading: false,
          });
        }
      });
    },
    [selectedItems, newlyAddedItems]
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [selectedItems, newlyAddedItems]);

  const handleTableChange = async (table: Table) => {
    await dispatch(getOpenTables());
    await dispatch(setSelectedTable(table));
    await dispatch(getCurrentTable(table?.id));
    await dispatch(calculateTotalPrice());
  };

  const onDescriptionAdded = async (description: string) => {
    await dispatch(updateItem({itemId: selectedArticle, description: description, items: selectedItems}));
    setDescModalOpen(false);
  }
  

  return (
    <>
      <S.ProfileColumn style={{ height: "85%" }}>
        <BaseRow justify="space-between">
          <BaseCol>
            <Typography style={{ color: BASE_COLORS.white }}></Typography>
          </BaseCol>

          <BaseCol>
            <Typography
              style={{ color: BASE_COLORS.white, fontSize: "1.5rem" }}
            >
              {" "}
              {selectedTable !== null && selectedTable !== undefined
                ? `Table ${selectedTable?.numero}`
                : "Selectionner une table"}
            </Typography>
          </BaseCol>

          <BaseCol>
            <Typography
              style={{ color: BASE_COLORS.white, fontSize: "0.75rem" }}
            >
              {timeNow.split(" ")[0]}
            </Typography>
            <Typography
              style={{ color: BASE_COLORS.white, fontSize: "0.75rem" }}
            >
              {timeNow.split(" ")[1]}
            </Typography>
          </BaseCol>
        </BaseRow>
        <BaseTable
          size="small"
          pagination={false}
          columns={ingredientsColumns}
          dataSource={selectedItems && selectedItems}
          rowKey={(record) => record.article_id}
          scroll={{ y: "calc(280px - 54px)" }}
          style={{ marginTop: "10px", height: "70%" }}
        />
      </S.ProfileColumn>
      <BaseCol style={{ height: "15%" }}>
        <BaseRow>
          <BaseCol span={14}>
            <S.Div>
              <S.Tables>
                <Typography
                  style={{ color: BASE_COLORS.white, fontSize: "0.75em" }}
                >
                  Tables
                </Typography>
                <Typography
                  style={{ color: BASE_COLORS.white, fontSize: "0.75rem" }}
                >
                  Ouvertes
                </Typography>
              </S.Tables>
              <S.Div>
              {openTables &&
                openTables.map((table: Table) => (
                  <S.TablesList
                    key={table.id}
                    onClick={() => handleTableChange(table)}
                  >
                    <Typography
                      style={{
                        cursor: "pointer",
                        color: BASE_COLORS.white,
                        fontSize: "1rem",
                      }}
                    >
                      {table.numero}
                    </Typography>
                  </S.TablesList>
                ))}
                </S.Div>
            </S.Div>
          </BaseCol>

          <BaseCol span={10} style={{ marginTop: "-2vh" }}>
            <BaseRow>
              {discount && (
                <Typography
                  style={{ color: BASE_COLORS.white, marginTop: "1rem" }}
                >
                  {discount}% Off
                </Typography>
              )}
            </BaseRow>
            <BaseRow style={{marginTop: "15px"}}>
              <Typography style={{ color: BASE_COLORS.white }}>
                {"Total dû  "}
              </Typography>
              <Typography
                style={{
                  color: BASE_COLORS.white,
                  fontWeight: 800,
                  fontSize: 18,
                  marginTop: "-2px",
                }}
              >
                &nbsp; {totalPrice?.toFixed(2)} Dhs
              </Typography>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseCol>
      <BaseModal
        onOk={() => onDescriptionAdded(description || '')}
        size="small"
        title="Vérifier votre PIN"
        open={descModalOpen}
        onCancel={() => setDescModalOpen(false)}
      >
        <BaseCol>
          <TextArea
            placeholder="description"
            rows={2}
            value={description || ''}
            onChange={(e:any) => setDescription(e.target.value)}
          />
        </BaseCol>
      </BaseModal>
    </>
  );
};

export default ProductsTable;

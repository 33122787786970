import axios, { AxiosError, AxiosResponse } from "axios";
import { readToken } from "../services/localStorage.service";

export interface Table {
  id: number;
  numero: string;
  statut: "ouvert" | "ferme";
  magasin_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = readToken();
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export const fetchRestaurantTablesApi = async (): Promise<Table[]> => {
  const apiUrl = `/tables`; // Update the API endpoint based on your backend

  try {
    const response: AxiosResponse<{ tables: Table[] }> =
      await axiosInstance.get(apiUrl);
    return response.data.tables;
  } catch (error: any) {
    console.error("Fetch tables failed", error);
    throw error.response?.data || "Fetch tables failed";
  }
};

export const fetchOrderApi = async (apiUrl: string): Promise<any> => {
  try {
    const response: AxiosResponse<{ tables: any[] }> = await axiosInstance.get(
      apiUrl
    );
    return response.data;
  } catch (err: any) {
    console.error("Fetch tables failed", err);
    throw err.response?.data || "Fetch tables failed";
  }
};

export const getCurrentTableApi = async (tableId: number): Promise<any> => {
  try {
    const response: AxiosResponse<{ tables: any[] }> = await axiosInstance.get(
      `tablesEnCours/${tableId}`
    );
    return response.data;
  } catch (err: any) {
    console.error("Fetch tables failed", err);
    throw err.response?.data || "Fetch tables failed";
  }
};

export const fetchOpenRestaurantTablesApi = async (): Promise<Table[]> => {
  const apiUrl = `/table/ouvertes`;

  try {
    const response: AxiosResponse<{ tablesOuvertes: Table[] }> =
      await axiosInstance.get(apiUrl);
    const openTables = response.data.tablesOuvertes;
    return openTables;
  } catch (error: any) {
    console.error("Fetch tables failed", error);
    throw error.response?.data || "Fetch tables failed";
  }
};

export const addRestaurantTableApi = async (
  newProduct: Table,
  apiUrl: string
): Promise<Table> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<Table>(
      apiUrl,
      newProduct
    );
    return response.data.category;
  } catch (error: any) {
    console.error("Add Table failed", error);
    throw error.response?.data || "Add Table failed";
  }
};

export const verifyAdminPINAPI = async (
  pinCode: number,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(apiUrl, {
      pin: pinCode,
    });
    return response.data;
  } catch (error: any) {
    console.error("Check failed", error);
    throw error.response?.data || "Check failed";
  }
};

export const setDiscountApi = async (data:any): Promise<any> => {
  try {
    console.log(data);
    const response: AxiosResponse<any> = await axiosInstance.put<any>(`tablesEnCours/${data?.id}`, data);
    return response.data;
  } catch (error: any) {
    console.error("Check failed", error);
    throw error.response?.data || "Check failed";
  }
};

export const cancelOrderApi = async (
  order: any,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(apiUrl, order);
    return response.data;
  } catch (error: any) {
    console.error("Check failed", error);
    throw error.response?.data || "Check failed";
  }
};

export const openTableApi = async (
  newProduct: number,
  apiUrl: string
): Promise<Table> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      apiUrl,
      newProduct
    );
    return response.data;
  } catch (error: any) {
    console.error("Add Table failed", error);
    throw error.response?.data || "Add Table failed";
  }
};

export const addCurrentTableApi = async (
  newCurrentTable: any,
  apiUrl: string
): Promise<Table> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      apiUrl,
      newCurrentTable
    );
    return response.data;
  } catch (error: any) {
    console.error("Add Table failed", error);
    throw error.response?.data || "Add Table failed";
  }
};

export const addNewOrderApi = async (
  newOrder: any,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<Table>(
      apiUrl,
      newOrder
    );
    return response.data.category;
  } catch (error: any) {
    console.error("Add Table failed", error);
    throw error.response?.data || "Add Table failed";
  }
};

export const updateRestaurantTableApi = async (
  updatedTable: Table,
  tableId: any,
  apiUrl: string
): Promise<Table> => {
  try {
    const response: AxiosResponse<Table> = await axiosInstance.put<Table>(
      `${apiUrl}/${tableId}`,
      updatedTable
    );
    return response.data;
  } catch (error: any) {
    console.error("Update Table failed", error);
    throw error.response?.data || "Update Table failed";
  }
};

export const deleteRestaurantTableApi = async (
  tableId: number,
  apiUrl: string
): Promise<void> => {
  try {
    await axiosInstance.delete<void>(`${apiUrl}/${tableId}`);
  } catch (error: any) {
    console.error("Delete table failed", error);
    throw error.response?.data || "Delete table failed";
  }
};

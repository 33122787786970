import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import logo from '../../../../assets/logo.png';
import logoDark from '../../../../assets/logo-dark.png';
import pos_logo from '../../../../assets/images/FAVICON.png';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useResponsive } from '../../../../hooks/useResponsive';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
  posSider?: boolean;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider, posSider }) => {
  const { tabletOnly } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);

  const img = theme === 'dark' ? logoDark : logo;

  return (
    <S.SiderLogoDiv>
      
        {posSider ? (
          <img src={pos_logo} alt="Lightence" width={60} height={60} />
        ) : (
          <S.SiderLogoLink to="/">
          <img src={img} alt="Lightence" width={120} height={42} />
          </S.SiderLogoLink>

        )}
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};

import React, { useEffect } from "react";
import * as S from "./SalesRepartition.styles";
import { Typography } from "antd";
import { useResponsive } from "../../../../hooks/useResponsive";
import { RootState } from "../../../../store/store";
import { BaseCol } from "../../../common/BaseCol/BaseCol";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { BASE_COLORS } from "../../../../styles/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardOrCashSum,
  getSalesSum,
} from "../../../../store/slices/dashboardSlice";

export const SalesRepartition: React.FC = () => {
  const dispatch = useDispatch();
  const {
    cardOrCashSum,
    somme_ventes}:any = useSelector((state: RootState) => state.dashboardReducer) || {};
  const { isTablet: isTabletOrHigher } = useResponsive();

  useEffect(() => {
    dispatch(getSalesSum());
    dispatch(getCardOrCashSum());
  }, []);

  return (
    <S.StatisticCard
      headStyle={{ fontSize: "1.2rem" }}
      title="Répartition des ventes"
      padding={"1rem"}
      $color={BASE_COLORS.white}
    >
      <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
        <BaseCol flex={1}>
          <BaseRow
            justify="space-between"
            wrap={false}
            style={{ marginTop: "0.5rem", padding: "0 1rem 0 1rem" }}
          >
            <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
              Ventes au Cash
            </Typography.Text>
            <Typography.Text style={{ color: BASE_COLORS.lighGreen }}>
              MAD {cardOrCashSum?.sommeVentesCash || 0}
            </Typography.Text>
          </BaseRow>
          <BaseRow
            justify="space-between"
            wrap={false}
            style={{ marginTop: "1.5rem", padding: "0 1rem 0 1rem" }}
          >
            <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
              Ventes au Carte
            </Typography.Text>
            <Typography.Text style={{ color: BASE_COLORS.lighGreen }}>
              MAD {cardOrCashSum?.sommeVentesCard || 0}
            </Typography.Text>
          </BaseRow>
          <BaseRow
            justify="space-between"
            wrap={false}
            style={{
              marginTop: "1.5rem",
              marginBottom: "0.5rem",
              padding: "0 1rem 0 1rem",
            }}
          >
            <Typography.Text style={{ color: BASE_COLORS.darkGreen }}>
              Ventes totales
            </Typography.Text>
            <Typography.Text style={{ color: BASE_COLORS.lighGreen }}>
              MAD {parseInt(somme_ventes?.somme_ventes)?.toFixed(2)}
            </Typography.Text>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </S.StatisticCard>
  );
};

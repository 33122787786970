// categories.api.ts

// import { axiosInstance } from '@app/api/http.api';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { readToken } from '../services/localStorage.service';

export interface Category {
  id: number;
  name: string;
  photo: string;
  magasin_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
    baseURL: apiUrl,
  });
  
  axiosInstance.interceptors.request.use((config:any) => {
    const token = readToken();
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    return config;
  });
  
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('HTTP error response:', error.response.data || 'No response data available');
        return Promise.reject(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('HTTP no response:', error.request);
        return Promise.reject({ message: 'No response received from the server' });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('HTTP request setup error:', error.message);
        return Promise.reject({ message: 'Error setting up the request' });
      }
    }
  );

export const fetchCategoriesApi = async (apiUrl: string): Promise<Category[]> => {
    try {
      const response: AxiosResponse<Category[]> = await axiosInstance.get<any>(apiUrl);
      return response.data;
    } catch (error:any) {
      console.error('Fetch categories failed', error);
      throw error.response?.data || 'Fetch categories failed';
    }
  };

  export const fetchCategoriesWithArticlesApi = async (apiUrl: string): Promise<Category[]> => {
    try {
      const response: AxiosResponse<Category[]> = await axiosInstance.get<any>(apiUrl);
      return response.data;
    } catch (error:any) {
      console.error('Fetch categories failed', error);
      throw error.response?.data || 'Fetch categories failed';
    }
  };

  export const addCategoryApi = async (newCategory: Category, apiUrl: string): Promise<Category> => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.post<Category>(apiUrl, newCategory);
      console.log(response)
      return response.data.category;
    } catch (error:any) {
      console.error('Add category failed', error);
      throw error.response?.data || 'Add category failed';
    }
  };
  
  export const updateCategoryApi = async (updatedCategory: Category, apiUrl: string): Promise<Category> => {
    try {
      const response: AxiosResponse<Category> = await axiosInstance.put<Category>(`${apiUrl}/${updatedCategory.id}`, updatedCategory);
      return response.data;
    } catch (error:any) {
      console.error('Update category failed', error);
      throw error.response?.data || 'Update category failed';
    }
  };
  
  export const deleteCategoryApi = async (categoryId: number, apiUrl: string): Promise<void> => {
    try {
      await axiosInstance.delete<void>(`${apiUrl}/${categoryId}`);
    } catch (error:any) {
      console.error('Delete category failed', error);
      throw error.response?.data || 'Delete category failed';
    }
  };
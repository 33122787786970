import React from "react";
import * as S from "./reporting.styles";
import Typography from "antd/lib/typography/";
import { SnippetsOutlined } from '@ant-design/icons';

const ReportingCard: React.FC = () => {

  return (
    <S.GeneralCard style={{textAlign: "center",  padding: "15vh 0", height: '75vh' }}>
  <SnippetsOutlined style={{ fontSize: "8vw" }} />
  <Typography.Title>Aucune transaction dans cette période</Typography.Title>
  <Typography.Text>Aucune transaction n'a eu lieu pendant la période que vous avez sélectionnée.</Typography.Text>
</S.GeneralCard>

  );
};

export default ReportingCard;

import styled, { css } from 'styled-components';
import { media } from '../../../../../styles/constants';
import { BaseTypography } from '../../../../common/BaseTypography/BaseTypography';


export const Wrapper = styled.div`
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  margin: 5px;
  width: 12rem;
  height: 8rem; /* Fixed height for the card */
  position: relative;
`;

export const ProductWrapper = styled.div`
  text-align: center;
  width: 12rem;
  height: 8rem; /* Fixed height for the card */
  position: relative;
  filter: blur(0);
`;

export const ImgWrapper = styled.div`

  width: 10rem;
  height: 10rem;
  margin: 0 auto 1.25rem auto;
  display: flex;
  justify-content: center;
  border-radius: 10%;
  margin-bottom: -0.5rem;
  filter: blur(0);
  width: 15rem @media only screen and ${media.xl} {
    width: 11.125rem;
    margin: 0 auto 2rem auto;
  }

  & > span {
    margin: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);

    @media only screen and ${media.xl} {
      margin: 7px;
    }
  }
`;

export const Title = styled(BaseTypography.Text)`
  color: #FFFFFF;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1.5rem;
  text-align: left;
  position: absolute;
  font-family: "Alice";
  top: 0;
  left: 0;
  width: calc(100% - 3rem);

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  -webkit-line-clamp: 2; /* Set the number of lines */
  text-overflow: ellipsis;
`;

export const Subtitle = styled(BaseTypography.Text)`
  color: #FFFFFF;
  font-family: "Alice";
  margin: 0.8rem;
  font-weight: 600;
  font-size: 1rem;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Text = styled(BaseTypography.Text)`
  font-size: 0.75rem;
  text-align: left;

  color: var(--text-main-color);

  @media only screen and ${media.md} {
    text-align: center;
  }

  @media only screen and ${media.xl} {
    font-size: 0.875rem;
    text-align: left;
  }
`;

export const IconsWrapper = styled.div<{ showIcons: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  ${(props) =>
    props.showIcons &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

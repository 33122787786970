import React from "react";
import { BASE_COLORS } from "../../../../styles/constants";
import { BaseSpace } from "../../../common/BaseSpace/BaseSpace";
import { BaseButton } from "../../../common/BaseButton/BaseButton";
import { RightOutlined } from "@ant-design/icons"
import { PopularArticles } from "./PopularArticles/PopularArticles";
import { LowStock } from "./LowStock/LowStock";
import { useNavigate } from "react-router-dom";

export const ImportantInfo: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/reporting");

  };

  return (
      <BaseSpace direction="vertical" style={{marginTop: "1rem", width: "100%"}} size={20}>
        <BaseButton onClick={handleClick} style={{color: BASE_COLORS.darkGreen, width: "23vw", backgroundColor: BASE_COLORS.yellow, border: "none"}}>
            Rapports Detaillés <RightOutlined style={{ height: "1rem"}} />
        </BaseButton>
        <PopularArticles/>
        <LowStock />
      </BaseSpace>
  );
};

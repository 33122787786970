import userReducer from './userSlice';
import authReducer from './authSlice';
import themeReducer from './themeSlice';
import pwaReducer from './pwaSlice';
import categoryReducer from './categoriesSlice';
import ingredientReducer from './ingredientsSlice';
import productReducer from './productsSlice';
import RestaurantTableReducer from './restaurantTablesSlice';
import ordersReducer from './ordersSlice';
import teamReducer from './teamSlice';
import dashboardReducer from './dashboardSlice';
import reportingReducer from './reportingSlice';

const reducers = {
  user: userReducer,
  auth: authReducer,
  categories: categoryReducer,
  ingredients: ingredientReducer,
  products: productReducer,
  tables: RestaurantTableReducer,
  order: ordersReducer,
  dashboardReducer: dashboardReducer,
  reporting: reportingReducer,
  team: teamReducer,
  theme: themeReducer,
  pwa: pwaReducer,
};

export default reducers;

import React, { useState } from "react";
import * as S from "./POSSider.styles";
import { SiderLogo } from "../SiderLogo";
import { ReactComponent as TableIcon } from "../../../../../assets/icons/pos/table.svg";
import { ReactComponent as DiscountIcon } from "../../../../../assets/icons/pos/discount.svg";
import { ReactComponent as DisconnectIcon } from "../../../../../assets/icons/pos/disconnect.svg";
import { BaseCol } from "../../../../common/BaseCol/BaseCol";
import { Discount } from "../../../../POS/discount/Discount";
import { RestaurantTables } from "../../../../POS/restaurantTables/RestaurantTables";
import { BASE_COLORS } from "../../../../../styles/constants";
import Typography from "antd/lib/typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { BaseModal } from "../../../../common/BaseModal/BaseModal";
import { useNavigate } from "react-router-dom";
import { fetchRestaurantTables } from "../../../../../store/slices/restaurantTablesSlice";

interface PosSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const PosSider: React.FC<PosSiderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const order = useSelector((state: RootState) => state.order.request);
  const dispatch = useDispatch();
  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [isDiscountModalOpen, setDiscountModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSider: any = () => setCollapsed(!isCollapsed);

  const handleView = () => {
    setViewModalOpen(true);
  };

  if(isViewModalOpen) {
    dispatch(fetchRestaurantTables());
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
  };

  const handleDiscountIconClick = () => {
    if (order?.selectedTable === null || order?.selectedTable === undefined || order?.numCommande === undefined || order?.numCommande === null) {
      BaseModal.warning({
        title: "Vous n'avez pas sélectionné de table",
        content: "Sélectionnez un tableau s'il vous plaît avant d'ajouter des produits",
      });
    } else {
      setDiscountModalOpen(true);
    }
  };

  const handleCloseDiscountModal = () => {
    setDiscountModalOpen(false);
  };

  const disconnectClick = () => {
    navigate('/choose')
  }

  return (
    <>
      <S.Sider trigger={null} width={80} {...props}>
        <SiderLogo
          posSider={true}
          isSiderCollapsed={isCollapsed}
          toggleSider={toggleSider}
        />
        <S.SiderContent>
          <BaseCol style={{ justifyContent: "space-between" }}>
            <BaseCol>
              <S.SiderLogoDiv style={{ padding: 15 }}>
                <TableIcon
                  style={{ cursor: "pointer" }}
                  width={45}
                  height={60}
                  onClick={handleView}
                />
              </S.SiderLogoDiv>
              <Typography
                style={{
                  marginLeft: "0.8rem",
                  marginTop: "-1rem",
                  color: BASE_COLORS.white,
                  fontSize: "18px",
                }}
              >
                Tables
              </Typography>

              <S.SiderLogoDiv style={{ padding: 15 }}>
                <DiscountIcon
                  style={{ cursor: "pointer" }}
                  width={45}
                  height={60}
                  onClick={handleDiscountIconClick}
                />
              </S.SiderLogoDiv>
              <Typography
                style={{
                  marginLeft: "0.3rem",
                  marginTop: "-1rem",
                  color: BASE_COLORS.white,
                  fontSize: "15px",
                }}
              >
                Réduction
              </Typography>
            </BaseCol>
          </BaseCol>
        </S.SiderContent>
        <BaseCol>
          <S.SiderLogoDiv onClick={disconnectClick} style={{ padding: 15 }}>
            <DisconnectIcon
              style={{ cursor: "pointer" }}
              width={45}
              height={60}
            />
          </S.SiderLogoDiv>
        </BaseCol>
      </S.Sider>
      {/* {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />} */}
      <RestaurantTables
        open={isViewModalOpen}
        onCancel={handleCloseViewModal}
      />
      <Discount
        open={isDiscountModalOpen}
        onCancel={handleCloseDiscountModal}
      />
    </>
  );
};

export default PosSider;

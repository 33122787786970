import React from 'react';
import { EChartsOption } from 'echarts-for-react';
import { BaseChartProps, BaseChart } from './BaseChart';

interface PieChartProps extends BaseChartProps {
  option?: EChartsOption;
  data?: any;
  name?: string;
  showLegend?: boolean;
}

export const PieChart: React.FC<PieChartProps> = ({ option, data, name, showLegend, ...props }) => {

  const defaultPieOption = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left'
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: data,
          label: {
            formatter: '{d}%',
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            }
          }
        }
      ]
  };
  return <BaseChart style={{marginBottom: "-3rem", marginTop: "-1rem"}} height={250} {...props} option={{ ...defaultPieOption, ...option }} />;
};

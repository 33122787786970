import axios, { AxiosError, AxiosResponse } from "axios";
import { readToken } from "../services/localStorage.service";

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = readToken();
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export interface Member {
    id: number;
    nom: string;
    titre: string;
    date_embauche: Date | undefined;
    salaire: number;
    phone: string;
    email: string;
    magasin_id: number;
}

  export const getMembersApi = async (apiUrl: any): Promise<Member[]> => {
  
    try {
      const response: AxiosResponse<{ equipes: Member[] }> =
        await axiosInstance.get(apiUrl);
      return response.data.equipes;
    } catch (error: any) {
      console.error("Fetch tables failed", error);
      throw error.response?.data || "Fetch tables failed";
    }
  };

  export const getOneMemberApi = async (apiUrl: any, id :number): Promise<any> => {
  
    try {
      const response: AxiosResponse<{ equipes: any }> =
        await axiosInstance.get(apiUrl+'/'+id);
      return response.data;
    } catch (error: any) {
      console.error("Fetch tables failed", error);
      throw error.response?.data || "Fetch tables failed";
    }
  };

  export const addNewMemberApi = async (
    newMember: Member,
    apiUrl: string
  ): Promise<Member> => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.post<Member>(
        apiUrl,
        newMember
      );
      return response.data;
    } catch (error: any) {
      console.error("Add Table failed", error);
      throw error.response?.data || "Add member failed";
    }
  };
  

  export const editMemberApi = async (
    member: Member,
    apiUrl: string
  ): Promise<Member> => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.put<Member>(
        `${apiUrl}/${member.id}`,
        member
      );
      return response.data;
    } catch (error: any) {
      console.error("Add Table failed", error);
      throw error.response?.data || "Add member failed";
    }
  };
  

  export const deleteMemberApi = async (memberId: number, apiUrl: string): Promise<void> => {
    try {
      await axiosInstance.delete<void>(`${apiUrl}/${memberId}`);
    } catch (error:any) {
      console.error('Delete member failed', error);
      throw error.response?.data || 'Delete member failed';
    }
  };
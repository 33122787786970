import React from "react";
import { BaseRow } from "../../components/common/BaseRow/BaseRow";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import { BaseCol } from "../../components/common/BaseCol/BaseCol";
import * as S from "./DashboardPage.styles";
import { TotalNumbers } from "../../components/dashboard/components/TotalNumbers/TotalNumbers";
import { ImportantInfo } from "../../components/dashboard/components/ImportantInfo/ImportantInfo";
import { SalesChart } from "../../components/dashboard/components/SalesChart/SalesChart";
import { BaseSpace } from "../../components/common/BaseSpace/BaseSpace";
import { SalesRepartition } from "../../components/dashboard/components/SalesRepartition/SalesRepartition";
import { BaseCard } from "../../components/common/BaseCard/BaseCard";
import { BASE_COLORS } from "../../styles/constants";
import { FileSearchOutlined } from "@ant-design/icons";
import { BaseTypography } from "../../components/common/BaseTypography/BaseTypography";

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <S.Main>
      <BaseCol span={17} style={{ padding: "1rem" }}>
        <BaseSpace size={10} direction="vertical">
          <TotalNumbers></TotalNumbers>
          <SalesChart></SalesChart>
          <BaseRow justify={"space-between"}>
            <BaseCol span={12}>
              <SalesRepartition></SalesRepartition>
            </BaseCol>
            <BaseCol span={11}>
              <BaseCard
                style={{ backgroundColor: '#1BB2B2', textAlign: "center",  padding: "2vh 0" }}
              >
                <BaseCol>
                  <FileSearchOutlined style={{color: BASE_COLORS.white, fontSize: '2rem'}} />
                </BaseCol>
                <BaseCol>
                  <BaseTypography.Text style={{color: BASE_COLORS.white}}>
                    Aucune donnée disponible
                  </BaseTypography.Text>
                </BaseCol>
              </BaseCard>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </BaseCol>
      <BaseCol span={7}>
        <ImportantInfo></ImportantInfo>
      </BaseCol>
    </S.Main>
  );

  const mobileAndTabletLayout = (
    <BaseCol>
      <BaseRow style={{ padding: "1rem" }}>
        <BaseSpace size={10} direction="vertical">
          <TotalNumbers></TotalNumbers>
          <SalesChart mobile={true}></SalesChart>
          <SalesRepartition></SalesRepartition>
        </BaseSpace>
      </BaseRow>
      <BaseRow style={{ marginLeft: "1rem" }}>
        <ImportantInfo></ImportantInfo>
      </BaseRow>
    </BaseCol>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;

import React, { useEffect } from "react";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { BaseCol } from "../../../common/BaseCol/BaseCol";
import { NumbersCard } from "./TotalCard/Card";
import { BASE_COLORS } from "../../../../styles/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  getAverageSpending,
  getCanceledSalesSum,
  getSalesSum,
} from "../../../../store/slices/dashboardSlice";

export const TotalNumbers: React.FC = () => {
  const dispatch = useDispatch();
  const {
    somme_ventes,
    sommeVentesAnnule,
    Depense_moyenne_par_personnes,
  }: any = useSelector((state: RootState) => state.dashboardReducer);
  useEffect(() => {
    dispatch(getSalesSum());
    dispatch(getCanceledSalesSum());
    dispatch(getAverageSpending());
  }, []);

  return (
    <BaseRow justify={"space-between"}>
      <BaseCol style={{ width: "17vw" }}>
        <NumbersCard
          name={"Total Des Ventes"}
          value={somme_ventes?.somme_ventes || 0}
          type="total"
          description={"*Mis à jour à chaque commande"}
          backgroundColor={BASE_COLORS.lighGreen}
          textColor={BASE_COLORS.white}
        />
      </BaseCol>
      <BaseCol style={{ width: "17vw" }}>
        <NumbersCard
          name={"Total Des Ventes Annulés"}
          type={"canceled"}
          value={parseInt(sommeVentesAnnule?.somme_ventes)?.toFixed(2) || 0}
          description={sommeVentesAnnule?.nombre_commandes_annulees || 0}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
        />
      </BaseCol>
      <BaseCol style={{ width: "17vw" }}>
        <NumbersCard
          name={"Dépense moyenne par personne"}
          value={Depense_moyenne_par_personnes?.Dépense_moyenne_par_personnes?.toFixed(2) || 0}
          description={Depense_moyenne_par_personnes?.somme_personnes || 0}
          type={"average"}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
        />
      </BaseCol>
    </BaseRow>
  );
};

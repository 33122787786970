import styled from "styled-components";
import { FONT_SIZE, media, FONT_WEIGHT } from "../../../styles/constants";
import { BaseAvatar } from "../../common/BaseAvatar/BaseAvatar";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import loginBg from '../../../assets/images/login.png';
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { BaseImage } from "../../common/BaseImage/BaseImage";

export const LoginDescription = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;

export const RememberMeText = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
`;

export const ForgotPasswordText = styled.span`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.xs};
  text-decoration: underline;
`;

export const User = styled(BaseAvatar)`
  height: 80px; 
  width: 80px;
  margin-bottom: 1vh;
  text-align: center;
`;


export const LoginSide = styled(BaseCol)`
  background-color: #f0f0ec;
  height: 100%;
  align-items: center;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const Contact = styled(BaseCol)`
  height: 100%;
  align-items:center;
  background-image: ${loginBg} !important;
  background-color: #009963;
  flex: 1;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden; /* Make sure the pseudo-element doesn't overflow */
  z-index: 0; /* Ensure the pseudo-element is behind the content */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${loginBg});
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Adjust the transparency as needed */
    z-index: -1; /* Place the pseudo-element behind the content */
  }

`;

export const LoginForm = styled(BaseForm)`
  // width: 70%;
  padding: 0rem 12rem 5rem 12rem;
`;

export const LogoImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  marging-left: 10px;
  max-width: 150px; /* Adjust the width as needed */
`;

export const LogoImage = styled(BaseImage)`
  max-width: 150px; /* Adjust the width as needed */
  height: auto;
  margin-bottom: 20px; /* Add margin to separate the image and text */
`;

export const LoginMobile = styled.div`
  background-color: #f0f0ec;
  align-items: center;
  padding: 20px;
  height: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const LoginContact = styled.div`
  height: 40%;
  align-items:center;
  background-image: ${loginBg} !important;
  background-color: #009963;
  flex: 1;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden; /* Make sure the pseudo-element doesn't overflow */
  z-index: 0; /* Ensure the pseudo-element is behind the content */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url(${loginBg});
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Adjust the transparency as needed */
    z-index: -1; /* Place the pseudo-element behind the content */
  }
`;
import React from 'react';
import * as S from './Card.styles';
import { CategoryModel } from '../../../../../domain/CategoryModal';
import { BaseAvatar } from '../../../../common/BaseAvatar/BaseAvatar';
import { BaseCard } from '../../../../common/BaseCard/BaseCard';

interface CardProps {
  MenuCardData?: CategoryModel | null;
  onClick: () => void;
  isSelected: boolean;
}

export const Card: React.FC<CardProps> = ({ MenuCardData, onClick, isSelected }) => {

  return MenuCardData ? (
    <BaseCard
      padding={0}
      size="small"
      onClick={onClick}
      style={{borderRadius: 0, width: '8rem', height: '9rem', margin: "0.5rem", cursor: "pointer",
      backgroundColor: isSelected ? "#525563" : "#3D4151",
    }}
    >
      <S.ImgWrapper>
        <BaseAvatar size={100} shape="square" src={process.env.REACT_APP_IMAGE_URL+MenuCardData?.photo} alt={MenuCardData.name} />
      </S.ImgWrapper>
      <S.ContentWrapper>
        <S.Title style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{`${MenuCardData?.name}`}</S.Title>
      </S.ContentWrapper>
    </BaseCard>
  ) : null;
};
import React from 'react';
import {
  DashboardOutlined,
  BarcodeOutlined,
  UsergroupAddOutlined,
  StockOutlined,
  BorderInnerOutlined,
  CarOutlined,
  AccountBookOutlined,
  SnippetsOutlined,
  LogoutOutlined
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.menu.dashboard',
    key: 'dashboard',
    // TODO use path variable
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'common.menu.reporting',
    key: 'reporting',
    url: '/reporting',
    icon: <SnippetsOutlined />,
  },
  {
    title: 'common.menu.menu',
    key: 'menu',
    url: '/menu',
    icon: <BarcodeOutlined />,
  },
  {
    title: 'common.menu.team',
    key: 'team',
    url: '/team',
    icon: <UsergroupAddOutlined />,
  },
  {
    title: 'common.menu.inventory',
    key: 'inventory',
    url: '/inventory',
    icon: <StockOutlined />,
  },
  {
    title: 'common.menu.planning',
    key: 'planning',
    url: '/planning',
    icon: <BorderInnerOutlined />,
  },
  {
    title: 'common.menu.suppliers',
    key: 'suppliers',
    url: '/suppliers',
    icon: <CarOutlined />,
  },
  {
    title: 'common.menu.accounting',
    key: 'accounting',
    url: '/accounting',
    icon: <AccountBookOutlined />,
  },
  {
    title: 'Déconnection',
    key: 'logout',
    url: '/choose',
    icon: <LogoutOutlined />,
  },
];

import styled from 'styled-components';
import { BASE_COLORS } from '../../../styles/constants';

export const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const TableSquare = styled.div`
  display: flex;
  border-radius: 20px; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const TableNumber = styled.span`
  font-size: 1.5rem;
  color: ${BASE_COLORS.darkGreen};
`;
import styled from 'styled-components';
import { BaseCard } from '../../../../common/BaseCard/BaseCard';
import { BaseSpace } from '../../../../common/BaseSpace/BaseSpace';

export const ArticlesCard = styled(BaseCard)`
  line-height: 1;
  overflow: hidden;
  width: 100%;
  height: 36vh;
`;

export const SingleProduct = styled(BaseSpace)`

`;

export const ProductsList = styled(BaseSpace)`

`;
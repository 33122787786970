import axios, { AxiosError, AxiosResponse } from "axios";
import { readToken } from "../services/localStorage.service";

export interface Table {
  id: number;
  numero: string;
  statut: "ouvert" | "ferme";
  magasin_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = readToken();
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export const generateXReportApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const generateZReportApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getSalesSumApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getCanceledSalesSumApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getAverageSpendingApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getLowStockApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getCardOrCashSumApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getPopularArticlesApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getPopularArticlesbyDayApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getLastSevenDaysApi = async (
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl);
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}

export const getReportByDayApi = async (apiUrl: string, date: string) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get<any>(apiUrl, {params: {date: date}});
    return response.data;
  } catch (error: any) {
    console.error("report generation failed", error);
    throw error.response?.data || "report generation failed";
  }
}
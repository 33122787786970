import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Member,
  addNewMemberApi,
  deleteMemberApi,
  editMemberApi,
  getMembersApi,
  getOneMemberApi,
} from "../../api/team.api";

interface teamState {
  members: Member[];
  member: any;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: teamState = {
  member: undefined,
  members: [],
  loading: "idle",
};

const API_URL = "/equpies";

export const getMembers: any = createAsyncThunk(
  "team/fetchMembers",
  async () => {
    try {
      const members: any = await getMembersApi(API_URL);
      return members;
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  }
);

export const getOneMember: any = createAsyncThunk(
  "team/getOneMember",
  async (id: number) => {
    try {
      const member: any = await getOneMemberApi(API_URL, id);
      return member;
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  }
);

export const addnewMember: any = createAsyncThunk(
  "team/addNewMember",
  async (newMember: any, { dispatch, rejectWithValue }) => {
    console.log(newMember);
    try {
      const response = await addNewMemberApi(newMember, API_URL);
      dispatch(getMembers());
      return response;
    } catch (error) {
      console.error("Error adding order:", error);
      throw rejectWithValue(error);
    }
  }
);

export const editMember: any = createAsyncThunk(
  "team/editMember",
  async (member: any, { dispatch, rejectWithValue }) => {
    console.log(member)
    try {
      const response = await editMemberApi(member, API_URL);
      dispatch(getMembers());
      return response;
    } catch (error) {
      console.error("Error editing member:", error);
      throw rejectWithValue(error);
    }
  }
);

export const deleteMember:any = createAsyncThunk(
  "team/deleteMember",
  async (member: any, { dispatch }) => {
    const memberDeleted =  deleteMemberApi(member, API_URL);
    dispatch(getMembers());
    return memberDeleted;
  }
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addnewMember.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(addnewMember.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });

    builder.addCase(addnewMember.rejected, (state) => {
      state.loading = "failed";
    });
    builder
      .addCase(getMembers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.members = action.payload;
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.loading = "failed";
      });
    builder
      .addCase(getOneMember.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getOneMember.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.member = action.payload;
      })
      .addCase(getOneMember.rejected, (state, action) => {
        state.loading = "failed";
      });
  },
});

export default teamSlice.reducer;

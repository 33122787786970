import React, { useState } from 'react';
import * as S from './POSLayout.styles';
import PosSider from '../sider/POSSider/POSSider';
import { POSHeader } from '../POSHeader/POSHeader';
import CalculatorNumberPad from '../../../POS/calculatorPad/CalculatorPad';
import PosCategories from '../../../POS/posCategories/Categories';
import ProductsTable from '../../../POS/productsTable/ProductsTable';
import { BaseCol } from '../../../common/BaseCol/BaseCol';
import { BaseRow } from '../../../common/BaseRow/BaseRow';
import { BaseLayout } from '../../../common/BaseLayout/BaseLayout';

const POSLayout: React.FC = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  return (
    <S.LayoutMaster>
      <PosSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />

      <BaseLayout style={{height: "100vh"}}>
        <POSHeader />
        <BaseRow style={{ height: '100%', flexWrap: 'nowrap',background: '#1B1B1E' }}>
          <BaseCol style={{padding: "1.5vh"}} span={15}>
            <PosCategories />
          </BaseCol>

          <BaseCol span={9}>
            {/* Content for the second column */}
            <BaseRow style={{ height: '56%' }}>
              <BaseCol style={{ background: '#3d4151', flex: 1, padding: "2vh" }}>
              <ProductsTable />
              </BaseCol>
            </BaseRow>
            <BaseRow style={{ height: '2%' }}>
              <BaseCol style={{ background: '#1B1B1E', flex: 1 }}>
              </BaseCol>
            </BaseRow>
            <BaseRow style={{ height: '42%' }}>
              <BaseCol style={{ background: '#3D4151', flex: 1 }}>
                <CalculatorNumberPad />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseLayout>
    </S.LayoutMaster>
  );
};

export default POSLayout;

import React, { useState } from "react";
import * as S from "./POSHeader.styles";
import { ReactComponent as SendIcon } from "../../../../assets/icons/pos/send.svg";
import { ReactComponent as PayIcon } from "../../../../assets/icons/pos/pay.svg";
import { ReactComponent as ReceiptIcon } from "../../../../assets/icons/pos/receipt.svg";
import { BaseCol } from "../../../common/BaseCol/BaseCol";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { HeaderFullscreen } from "../../../header/components/HeaderFullscreen/HeaderFullscreen";
import { Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { MoneyCollectOutlined, CreditCardOutlined } from "@ant-design/icons";
import {
  addCurrentTable,
  clearRequest,
  addOrder,
} from "../../../../store/slices/ordersSlice";
import { RootState } from "../../../../store/store";
import { ClientReceipt } from "../../../../utils/receipts/clientReceipt";
import { SendReceipt } from "../../../../utils/receipts/sendReceipt";
import { BaseModal } from "../../../common/BaseModal/BaseModal";
import { BaseButton } from "../../../common/BaseButton/BaseButton";

export const POSHeader: React.FC = () => {
  const order = useSelector((state: RootState) => state.order.request);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [, setPaymentType] = useState<"cash" | "card">("cash");

  const dispatch = useDispatch();

  const handlePaymentSelection = (type: "cash" | "card") => {
    setPaymentType(type);
    handlePayment(type);
    setPaymentModalOpen(false);
  };
  const closePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const addnewOrderAction = async (actionName: string) => {
    if (order?.selectedTable === null || order?.selectedTable === undefined) {
      BaseModal.warning({
        title: "Vous n'avez pas sélectionné de table",
        content: "Sélectionnez un tableau s'il vous plaît avant d'ajouter des produits",
      });
    }else {
      if (actionName === "send") {
        const { selectedTable, personsNumber, items } = order;
  
        const formattedData = {
          table_id: selectedTable?.id,
          nbr_personnes: personsNumber,
          articles: items?.map((item: any) => ({
            article_id: item.article_id,
            nom: item.nom,
            quantite: item.quantite,
            prix: parseInt(item.prix),
            ...(item.description ? { description: item.description } : null),
          })),
        };
        await dispatch(addCurrentTable(formattedData));
        SendReceipt(order);
        dispatch(clearRequest());
      } else if (actionName === "recu") {
        ClientReceipt(order);
        dispatch(clearRequest());
      } else if (actionName === "pay") {
        if (order?.selectedTable === null || order?.selectedTable === undefined) {
          BaseModal.warning({
            title: "You didn't select a table",
            content: "Select a table please before adding any products",
          });
        } else {
          setPaymentModalOpen(true);
        }
      } else if (actionName === "recu") {
        if (order?.selectedTable === null || order?.selectedTable === undefined) {
          BaseModal.warning({
            title: "You didn't select a table",
            content: "Select a table please before adding any products",
          });
        } else {
          ClientReceipt(order);
        }
      }
    }
  };

  const handlePayment = async (type: "cash" | "card") => {
    const { selectedTable, personsNumber, items, total, numCommande } = order;
    const formattedData = {
      table_id: selectedTable?.id,
      numCommande: numCommande,
      montant: total,
      nbr_personnes: personsNumber,
      type_payement: type,
      articles: items?.map((item: any) => ({
        article_id: item.article_id,
        quantite: item.quantite,
        ...(item.description ? { description: item.description } : null),
      })),
    };
    await dispatch(addOrder(formattedData));
    await ClientReceipt(order);
    dispatch(clearRequest());
    setPaymentModalOpen(false);
  };

  return (
    <>
      <S.Header $isTwoColumnsLayoutHeader={false}>
        <BaseRow justify={"space-between"}>
          <S.SearchColumn xl={14} xxl={17}>
            <BaseRow justify="space-between">
              <BaseCol xl={15} xxl={12}>
                {/* <HeaderSearch /> */}
              </BaseCol>
            </BaseRow>
          </S.SearchColumn>

          {/* Icon buttons at the end */}
          <S.IconButtonsContainer>
            <BaseRow>
              <S.SiderLogoDiv onClick={() => addnewOrderAction("send")}>
                <SendIcon
                  width={40}
                  height={40}
                  style={{ cursor: "pointer" }}
                />
                <Typography style={{ color: "#38B6FF", fontSize: "15px" }}>
                  Envoyer
                </Typography>
              </S.SiderLogoDiv>
              <S.SiderLogoDiv onClick={() => addnewOrderAction("recu")}>
                <ReceiptIcon
                  width={40}
                  height={40}
                  style={{ cursor: "pointer" }}
                />
                <Typography style={{ color: "#FFBD59", fontSize: "15px" }}>
                  Reçu
                </Typography>
              </S.SiderLogoDiv>
              <S.SiderLogoDiv onClick={() => addnewOrderAction("pay")}>
                <PayIcon width={40} height={40} style={{ cursor: "pointer" }} />
                <Typography style={{ color: "#1DC194", fontSize: "15px" }}>
                  Payer
                </Typography>
              </S.SiderLogoDiv>
              <S.SiderLogoDiv>
                <HeaderFullscreen isPOS={true} />
              </S.SiderLogoDiv>
            </BaseRow>
          </S.IconButtonsContainer>
        </BaseRow>
      </S.Header>

      <BaseModal
        open={isPaymentModalOpen}
        onCancel={closePaymentModal}
        title="Choose Payment type"
        footer={null}
      >
        <BaseRow justify={"center"}>
          <BaseButton
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={() => handlePaymentSelection("cash")}
          >
            <MoneyCollectOutlined /> Payer en espèces
          </BaseButton>
          <BaseButton
            onClick={() => handlePaymentSelection("card")}
            type="default"
          >
            <CreditCardOutlined /> Payer par carte
          </BaseButton>
        </BaseRow>
      </BaseModal>
    </>
  );
};

import {
  PayloadAction,
  createAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Product, fetchProductsApi } from "../../api/product.api";
import {
  Table,
  addCurrentTableApi,
  addNewOrderApi,
  cancelOrderApi,
  fetchOpenRestaurantTablesApi,
  getCurrentTableApi,
  openTableApi,
  setDiscountApi,
  verifyAdminPINAPI,
} from "../../api/restaurantTable.api";
import { uid } from "react-uid";
import { Category, fetchCategoriesWithArticlesApi } from "../../api/category.api";

export interface SelectedItem {
  article_id: number;
  nom: string;
  quantite: number;
  prix_total: number | null;
  description?: string | null;
}

interface orderState {
  openTables: [];
  products: Product[];
  categories: Category[];
  request: {
    numCommande: number | null;
    selectedTable: Table | null;
    personsNumber: number;
    items: SelectedItem[];
    newlyAddedItems: SelectedItem[];
    discount: number;
    discountReason: string | null;
    cancelingReason: string | null;
    total: number;
    orderStatus: "initial" | "recu" | "send" | "reprint" | "pay" | "cancel";
  };
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: orderState = {
  products: [],
  categories: [],
  openTables: [],
  request: {
    numCommande: null,
    selectedTable: null,
    personsNumber: 0,
    items: [],
    discountReason: null,
    cancelingReason: null,
    newlyAddedItems: [],
    discount: 0,
    total: 0,
    orderStatus: "initial",
  },
  loading: "idle",
};

const API_URL = "/tables";

export const getCurrentTable: any = createAsyncThunk(
  "orders/fetchCurrentTable",
  async (tableId: number) => {
    try {
      const orders: any = await getCurrentTableApi(tableId);
      return orders;
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  }
);

export const selectProduct = createAction<Product>("tables/selectProduct");

export const setDiscount = createAction<number | 0>("discount/selectDiscount");
export const setDiscountReason = createAction<string | null>("discount/setDiscountReason");
export const setCancelReason = createAction<string | null>("cancel/setCancelReason");

export const setCurrentTable = createAction<any | null>("tables/currenTable");

export const setPeopleNumber = createAction<number>("order/setPeopleNumber");

export const getCategories: any = createAsyncThunk(
  "order/fetchCategories",
  async () => {
    const categories: any = await fetchCategoriesWithArticlesApi("/categoriesWithArticles");
    return categories;
  }
);

export const getProducts: any = createAsyncThunk(
  "order/fetchProducts",
  async (categoryId: number | undefined) => {
    const products: any = await fetchProductsApi(categoryId);
    return products;
  }
);

export const setSelectedTable = createAction<Table | null>(
  "tables/setSelectedTable"
);

export const calculateTotalPrice = createAction("order/calculateTotalPrice");

export const getOpenTables: any = createAsyncThunk(
  "tables/fetchOpenTables",
  async () => {
    const data: any = await fetchOpenRestaurantTablesApi();
    return data;
  }
);

export const verifyAdminPIN: any = createAsyncThunk(
  "order/verifyPin",
  async (pin: number, { rejectWithValue }) => {
    const CHECK_PIN_API = "/checkCodePin";
    try {
      const verified: any = await verifyAdminPINAPI(pin, CHECK_PIN_API);
      return verified.message;
    } catch (error: any) {
      return false;
    }
  }
);

export const setDiscountDB: any = createAsyncThunk(
  "order/verifyPin",
  async (data:any, { rejectWithValue }) => {
    try {
      const added: any = await setDiscountApi(data);
      return added.message;
    } catch (error: any) {
      return false;
    }
  }
);

export const cancelOrder: any = createAsyncThunk(
  "order/canelOrder",
  async (order: any, { rejectWithValue, dispatch }) => {
    try {
      const CANCEL_API = "/commandeAnnuler";
      const orderCanceled: any = await cancelOrderApi(order, CANCEL_API);
      dispatch(getOpenTables());

      return orderCanceled;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || "An error occurred while cancelling"
      );
    }
  }
);

export const openTable = createAsyncThunk(
  "tables/openTable",
  async (newTable: number) => {
    return openTableApi(newTable, API_URL);
  }
);

export const addCurrentTable: any = createAsyncThunk(
  "tables/AddCurentTable",
  async (order: any, { dispatch }) => {
    try {
      const ORDER_API_URL = "/tablesEnCours";

      let existingOrder: any;

      try {
        existingOrder = await dispatch(getCurrentTable(order.table_id));
      } catch (getCurrentTableError) {
        console.error("Error fetching current table:", getCurrentTableError);
      }

      if (!existingOrder.payload) {
        // If existingOrder is falsy, create a new order
        const requestData = {
          table_id: order.table_id,
          nbr_personnes: order.nbr_personnes,
          articles: order.articles,
          ...(order.reduction ? { reduction: order.reduction } : {}),
          ...(order.raison ? { raison: order.raison } : {}),
        };
        const response = await addCurrentTableApi(requestData, ORDER_API_URL);
        dispatch(getOpenTables());
        return response; // Add this return statement to avoid the second API call
      }

      const existingItems = existingOrder.payload?.articles;
      const peopleNumber = existingOrder.payload?.personsNumber;

      const updatedItems:any = order.articles?.forEach((newItem: any) => {
        const existingItemIndex = existingItems?.findIndex(
          (item: any) => item.article_id === newItem.article_id
        );

        if (existingItemIndex !== -1) {
          // Item already exists, update the quantity
          const existingItem = existingItems[existingItemIndex];

          // Ensure that existingItem is defined before accessing its properties
          if (existingItem) {
            return {
              ...existingItem,
              quantite: newItem.quantite,
              prix_total: newItem.prix_total,
            };
          }
        } else {
          // Item doesn't exist, add it
          return newItem;
        }
      });

      const requestData = {
        table_id: order.table_id,
        nbr_personnes: order.nbr_personnes || peopleNumber,
        articles: updatedItems,
        ...(order.reduction ? { reduction: order.reduction } : {}),
        ...(order.raison ? { raison: order.raison } : {}),
      };

      const response = await addCurrentTableApi(requestData, ORDER_API_URL);
      dispatch(getOpenTables());
      return response;
    } catch (error) {
      // Handle the error (order not found, etc.)
      console.error("Error adding current table:", error);
      throw error; // Propagate the error to be handled by the calling code
    }
  }
);

export const addOrder: any = createAsyncThunk(
  "tables/addNewOrder",
  async (newOrder: any, { dispatch, rejectWithValue }) => {
    const ORDER_API_URL = "/commandes";
    try {
      const response = await addNewOrderApi(newOrder, ORDER_API_URL);
      // Check if the response has ingredientStatut messages
      if (
        response?.ingredientStatut &&
        response?.ingredientStatut?.length > 0
      ) {
        // Extract the ingredientStatut messages
        const ingredientStatutMessages = response.ingredientStatut;
        // Generate a general alert message based on ingredientStatut content
        const alertMessage = ingredientStatutMessages.join("\n");
        if (alertMessage.trim() !== "") {
        }
      }
      dispatch(getOpenTables());

      return response;
    } catch (error) {
      console.error("Error adding order:", error);
      
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearRequest: (state: any) => {
      state.request = {
        id: undefined,
        selectedTable: null,
        personsNumber: 0,
        items: [],
        discount: 0,
        total: 0,
        orderStatus: "initial",
      };
    },
    initRequest: (state: any, action: PayloadAction<any | null>) => {
      // Todo: before init new request, check if table has already a request
      state.request = {
        id: uid(action.payload),
        selectedTable: action.payload,
        discount: 0,
        total: 0
      };
    },
    addItemToRequest: (
      state: any,
      action: PayloadAction<{
        productId: number;
        product: Product;
      }>
    ) => {
      const { productId, product } = action.payload;
    
      if (state.request.selectedTable) {
        const existingItemIndex = state.request.items?.findIndex(
          (item: any) => item.article_id === productId
        );
    
        const singleQuantity = 1;
    
        if (existingItemIndex !== undefined && existingItemIndex !== -1) {
          state.request.items[existingItemIndex] = {
            ...state.request.items[existingItemIndex],
            quantite: (state.request.items[existingItemIndex]?.quantite || 0) + 1,
            prix_total:
              (product.prix ?? 0) *
              ((state.request.items[existingItemIndex]?.quantite || 0) + 1),
          };
    
          // Add to newlyAddedItems only the newly added quantity
          state.request.newlyAddedItems = [
            ...(state.request.newlyAddedItems || []),
            {
              article_id: productId,
              nom: product.name,
              quantite: singleQuantity,
              prix_total: (product.prix ?? 0) * singleQuantity,
              description:
                product.description !== undefined
                  ? product.description
                  : null,
            },
          ];
    
          state.request.orderStatus = "initial";
        } else {
          // Create a new array for items to ensure proper state tracking
          state.request.items = [...(state.request.items || [])];
    
          state.request.items.push({
            article_id: productId,
            nom: product.name,
            quantite: singleQuantity,
            prix_total: (product.prix ?? 0) * singleQuantity,
            description:
              product.description !== undefined
                ? product.description
                : null,
          });
    
          // Similarly, create a new array for newlyAddedItems
          state.request.newlyAddedItems = [
            ...(state.request.newlyAddedItems || []),
            {
              article_id: productId,
              nom: product.name,
              quantite: singleQuantity,
              prix_total: (product.prix ?? 0) * singleQuantity,
              description:
                product.description !== undefined
                  ? product.description
                  : null,
            },
          ];
          console.log("new items", state.request.newlyAddedItems)
          console.log("items", state.request.items)
    
    
          state.request.orderStatus = "initial";
        }
      }
    },
    
    deleteItem: (state, action: PayloadAction<number>) => {
      const itemIdToDelete = action.payload;
      state.request.items = state.request.items.filter(
        (item) => item.article_id !== itemIdToDelete
      );
    },
    updateItem: (
      state,
      action: PayloadAction<{ itemId: number; description: string | null; items: any }>
    ) => {
      const { itemId, description } = action.payload;
    
      const existingItemIndex = state.request.items?.findIndex(
        (item: any) => item.article_id === itemId
      );
    
      if (existingItemIndex !== -1) {
        // Update the description of the existing item
        state.request.items[existingItemIndex].description = description;
    
        // Find the corresponding newly added item in newlyAddedItems
        const newlyAddedItemIndex = state.request.newlyAddedItems?.findIndex(
          (item: any) => item.article_id === itemId
        );
    
        if (newlyAddedItemIndex !== -1) {
          // Update the description of the corresponding newly added item
          state.request.newlyAddedItems[newlyAddedItemIndex].description = description;
        }
      }
    },
    setDiscount: (state, action: PayloadAction<number | 0>) => {
      state.request.discount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCurrentTable.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(addCurrentTable.fulfilled, (state, action) => {
      // Handle the fulfilled state, e.g., reset the order state
      state.loading = "succeeded";
      state.request.numCommande = action.payload.numCommande;
      clearRequest(); // Assuming you want to reset the request state after sending the order
    });

    builder.addCase(addCurrentTable.rejected, (state) => {
      // Handle the rejected state if needed
      state.loading = "failed";
    });
    builder.addCase(setPeopleNumber, (state, action) => {
      state.request.personsNumber = action.payload;
    });
    builder.addCase(getOpenTables.pending, (state) => {
      // Handle pending state if needed
      state.loading = "pending";
    });

    builder.addCase(getOpenTables.fulfilled, (state, action) => {
      // Handle the fulfilled state for fetchOpenTables
      state.loading = "succeeded";
      state.openTables = action.payload; // Assuming that the payload of fetchOpenTables contains the open tables data
    });

    builder.addCase(getOpenTables.rejected, (state) => {
      // Handle the rejected state if needed
      state.loading = "failed";
    });
    builder.addCase(setSelectedTable, (state, action) => {
      state.request.selectedTable = action.payload;
    });
    builder
      .addCase(getCurrentTable.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCurrentTable.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.request.items = action?.payload?.articles;
        state.request.numCommande = action?.payload?.numCommande;
        state.request.personsNumber = action?.payload?.nbr_personnes;
        state.request.discount = action?.payload?.reduction;
        state.request.discountReason = action?.payload?.raison;
        state.request.orderStatus = "send";
      })
      .addCase(getCurrentTable.rejected, (state, action) => {
        state.loading = "failed";
      });
    builder.addCase(calculateTotalPrice, (state) => {
      const total = state.request.items?.reduce((acc, item) => {
        const price = Number(item.prix_total) || 0;
        return acc + price;
      }, 0);

      state.request.total =
        state.request.discount > 0
          ? total * (1 - state.request.discount / 100)
          : total;
    });
    builder
      .addCase(addOrder.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.loading = "succeeded";
        clearRequest();
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.loading = "failed";
      });
    builder
      .addCase(setDiscountDB.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(setDiscountDB.fulfilled, (state, action) => {
        state.loading = "succeeded";
      })
      .addCase(setDiscountDB.rejected, (state, action) => {
        state.loading = "failed";
      });
    builder.addCase(setDiscount, (state, action) => {
      state.request.discount = action.payload;
    });
    builder.addCase(setDiscountReason, (state, action) => {
      state.request.discountReason = action.payload;
    });
    builder.addCase(setCancelReason, (state, action) => {
      state.request.cancelingReason = action.payload;
    });
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const {
  clearRequest,
  initRequest,
  addItemToRequest,
  deleteItem,
  updateItem,
} = orderSlice.actions;
export default orderSlice.reducer;

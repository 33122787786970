import React from "react";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { useResponsive } from "../../../../hooks/useResponsive";
import { BaseCol } from "../../../common/BaseCol/BaseCol";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { NumbersCard } from "./components/TotalNumbers/Card";
import { BASE_COLORS } from "../../../../styles/constants";
import { ChartCard } from "./components/PieChart/ChartCard";

const GeneralReport: React.FC = () => {
  const { isDesktop } = useResponsive();

  const {
    repartitionsVentes,
    somme_ventes,
    sommeVentesAnnule,
    produitsPopualires,
    Dépense_moyenne_par_personnes,
    produitsReduits
  }: any = useSelector((state: RootState) => state.reporting.reportingByDay) || {};

  const data: any = useSelector((state: RootState) => state.reporting.reportingByDay);
  
  console.log(data)
  const desktopLayout = (
    <BaseRow gutter={[16, 16]}>
      <BaseCol span={8}>
        <ChartCard
          backgroundColor={BASE_COLORS.white}
          value={repartitionsVentes}
          isPie={true}
          route={'/sales-repartition'}
          title={"Paiement en espèces/cartes"}
        />
      </BaseCol>
      <BaseCol span={8}>
        <NumbersCard
          plus={false}
          type="average"
          route="average-spent"
          name={"Dépense moyenne par personne"}
          value={Dépense_moyenne_par_personnes?.Dépense_moyenne_par_personnes?.toFixed(2)}
          visitors=" personnes visitées"
          titleColor={BASE_COLORS.darkGreen}
          description={Dépense_moyenne_par_personnes?.nombrePersonnes || 0}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
          buttonText={BASE_COLORS.white}
          buttonColor={BASE_COLORS.lighGreen}
        />
      </BaseCol>
      <BaseCol span={8}>
          <NumbersCard
            type="total"
            plus={true}
            name={"Total Des Ventes"}
            value={somme_ventes?.somme_ventes}
            titleColor={BASE_COLORS.white}
            description={""}
            visitors="*Mis à jour à chaque commande"
            backgroundColor={BASE_COLORS.lighGreen}
            textColor={BASE_COLORS.white}
            buttonText={BASE_COLORS.darkGreen}
            buttonColor={BASE_COLORS.yellow}
            route={"/total-sales"}
          />
      </BaseCol>
      <BaseCol span={8}>
        <NumbersCard
          name={"Total Des Ventes Annulés"}
          value={sommeVentesAnnule?.sommeVentesAnnule}
          plus={true}
          type="cancelled"
          route={'/canceled-sales'}
          description={sommeVentesAnnule?.nombre_commandes_annulees || 0}
          visitors=" commandes annulées"
          titleColor={BASE_COLORS.darkGreen}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
          buttonText={BASE_COLORS.white}
          buttonColor={BASE_COLORS.lighGreen}
        />
      </BaseCol>
      <BaseCol span={8}>
        <ChartCard
          route={'/popular-products'}
          backgroundColor={BASE_COLORS.white}
          popularProducts={produitsPopualires}
          isPie={false}
          title={"Produits Populaires"}
        />
      </BaseCol>
      <BaseCol span={8}>
        <NumbersCard 
          type="discount"
          name={"Réduction"}
          value={produitsReduits?.TotalommandesReduits}
          plus={true}
          visitors=" Commandes réduits"
          route={'/discounts'}
          description={produitsReduits?.NbrcommandesReduits || 0}
          titleColor={BASE_COLORS.darkGreen}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
          buttonText={BASE_COLORS.white}
          buttonColor={BASE_COLORS.lighGreen}
        />
      </BaseCol>
    </BaseRow>
  );

  const mobileLayout = (
    <BaseRow gutter={[16, 16]}>
      <BaseCol span={12}>
        <ChartCard
          backgroundColor={BASE_COLORS.white}
          value={repartitionsVentes}
          isPie={true}
          route={'/sales-repartition'}
          title={"Paiement en espèces/cartes"}
        />
      </BaseCol>
      <BaseCol span={12}>
        <NumbersCard
          type="average"
          name={"Dépense moyenne par personne"}
          plus={false}
          value={Dépense_moyenne_par_personnes?.Dépense_moyenne_par_personnes?.toFixed(2)}
          titleColor={BASE_COLORS.darkGreen}
          description={Dépense_moyenne_par_personnes?.nombrePersonnes || 0}
          visitors=" personnes visitées"
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
          buttonText={BASE_COLORS.white}
          buttonColor={BASE_COLORS.lighGreen}
        />
      </BaseCol>
      <BaseCol span={12}>
      <NumbersCard
            type="total"
            plus={true}
            name={"Total Des Ventes"}
            value={somme_ventes?.somme_ventes}
            titleColor={BASE_COLORS.white}
            description={""}
            visitors="*Mis à jour à chaque commande"
            backgroundColor={BASE_COLORS.lighGreen}
            textColor={BASE_COLORS.white}
            buttonText={BASE_COLORS.darkGreen}
            buttonColor={BASE_COLORS.yellow}
            route={"/total-sales"}
          />
      </BaseCol>
      <BaseCol span={12}>
      <NumbersCard
          name={"Total Des Ventes Annulés"}
          value={sommeVentesAnnule?.sommeVentesAnnule}
          plus={true}
          type="cancelled"
          route={'/canceled-sales'}
          description={sommeVentesAnnule?.nombre_commandes_annulees || 0}
          visitors=" commandes annulées"
          titleColor={BASE_COLORS.darkGreen}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
          buttonText={BASE_COLORS.white}
          buttonColor={BASE_COLORS.lighGreen}
        />
      </BaseCol>
      <BaseCol span={12}>
      <ChartCard
          route={'/popular-products'}
          backgroundColor={BASE_COLORS.white}
          popularProducts={produitsPopualires}
          isPie={false}
          title={"Produits Populaires"}
        />
      </BaseCol>
      <BaseCol span={12}>
      <NumbersCard 
          type="discount"
          name={"Réduction"}
          value={produitsReduits?.TotalommandesReduits}
          plus={true}
          visitors=" Commandes réduits"
          route={'/discounts'}
          description={produitsReduits?.NbrcommandesReduits || 0}
          titleColor={BASE_COLORS.darkGreen}
          backgroundColor={BASE_COLORS.white}
          textColor={BASE_COLORS.darkGreen}
          buttonText={BASE_COLORS.white}
          buttonColor={BASE_COLORS.lighGreen}
        />
      </BaseCol>
    </BaseRow>
  );

  return <>{isDesktop ? desktopLayout : mobileLayout}</>;
};

export default GeneralReport;

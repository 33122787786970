import styled, { css } from 'styled-components';
import { media } from '../../../../../styles/constants';
import { BaseTypography } from '../../../../common/BaseTypography/BaseTypography';

export const ImgWrapper = styled.div`
  width: 8rem;
  height: 7rem;
  margin: 0 auto 1.25rem auto;
  display: flex;
  justify-content: center;
  border-radius: 10%;
  margin-bottom: -0.5rem;
  filter: blur(0);
  width: 15rem @media only screen and ${media.xl} {
    width: 11.125rem;
    margin: 0 auto 2rem auto;
  }

  & > span {
    margin: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);

    @media only screen and ${media.xl} {
      margin: 7px;
    }
  }
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  margin-bottom: 0.5rem;
  text-align: center;
  @media only screen and ${media.xl} {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`;

export const IconsWrapper = styled.div<{ showIcons: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  ${props =>
    props.showIcons &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  margin-bottom: -1rem;
  margin-top: 0.5rem;
`;
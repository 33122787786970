
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Table, addRestaurantTableApi, deleteRestaurantTableApi, fetchRestaurantTablesApi, updateRestaurantTableApi } from '../../api/restaurantTable.api';

interface RestaurantsTablesState {
  tables: Table[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState: RestaurantsTablesState = {
  tables: [],
  loading: 'idle',
};

const API_URL = '/tables';

export const fetchRestaurantTables:any = createAsyncThunk('tables/fetchRestaurantTables', async () => {
  const restaurantsTables: any = await fetchRestaurantTablesApi();
  return restaurantsTables;
});

export const addRestaurantTable:any = createAsyncThunk('tables/addRestaurantTable', async (newTable: Table, { dispatch }) => {
  const tables = addRestaurantTableApi(newTable, API_URL);
  dispatch(fetchRestaurantTables());
  return tables;
});

export const updateRestaurantTable:any = createAsyncThunk(
  'tables/updateRestaurantTable',
  async ({ updatedTable, tableId }: { updatedTable: Table; tableId: any }, { dispatch }) => {
    const tables = updateRestaurantTableApi(updatedTable, tableId, API_URL);
    dispatch(fetchRestaurantTables());
    return tables;
  },
);

export const deleteRestaurantTable:any = createAsyncThunk('tables/deleteRestaurantTable', async (tableId: number, { dispatch }) => {
  const tables = deleteRestaurantTableApi(tableId, API_URL);
  dispatch(fetchRestaurantTables());
  return tables;
});

const RestaurantTablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRestaurantTables.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchRestaurantTables.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.tables = action.payload;
      })
      .addCase(fetchRestaurantTables.rejected, (state) => {
        state.loading = 'failed';
      })

      .addCase(addRestaurantTable.fulfilled, (state: any, action) => {
        const newTable: Table = action.payload;
        state.tables = [...state.tables, newTable];
      })

      .addCase(addRestaurantTable.rejected, (state) => {})
      .addCase(updateRestaurantTable.fulfilled, (state: any, action) => {
        const updatedTable: Table = action.payload;
        // Assuming you have a unique identifier like `id` for the tables
        state.tables = state.tables.map((table: Table) => (table.id === updatedTable.id ? updatedTable : table));
      });
  },
});

export default RestaurantTablesSlice.reducer;

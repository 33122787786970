import React, { useEffect } from 'react';
import * as S from '../Header.styles';
import { BaseRow } from '../../common/BaseRow/BaseRow';
import { useTitle } from '../../../contexts/TitleContext';
import { BaseTypography } from '../../common/BaseTypography/BaseTypography';
import { useLocation } from 'react-router-dom';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const { title, setTitle } = useTitle();
  const location = useLocation();

  useEffect(() => {
    // Set the title when the route changes
    if (location.pathname === '/') {
      setTitle('Bienvenue dans votre tableau de bord:');
    } else if (location.pathname === '/reporting') {
      setTitle('Bienvenue dans vos rapports détaillés!');
    } else if (location.pathname === '/menu') {
      setTitle('Gestion des menus');
      console.log(title);
    } // Add more conditions as needed
  }, [location.pathname, setTitle]);
  
  return (
    <BaseRow justify="space-between" align="middle">
       <BaseTypography.Title style={{fontSize: "1.5rem"}}>{title}</BaseTypography.Title>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "./components/categorieCard/Card";
import * as S from "./Categories.styles";
import { RootState } from "../../../store/store";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { ProductCard } from "./components/produtsCard/Product";
import { getCategories, getProducts } from "../../../store/slices/ordersSlice";

const PosCategories: React.FC = () => {
  const dispatch = useDispatch();
  const categories: any = useSelector(
    (state: RootState) => state?.order?.categories
  );
  console.log(categories);
  const products = useSelector((state: RootState) => state?.order?.products);
  const [selectedCategory, setSelectedCategory]: any = useState<number>();
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const onSelectCategory = async (categoryId: number) => {
    setSelectedCategory(categoryId);
    await dispatch(getProducts(categoryId));
  };

  return (
    <BaseCol
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "86vh",
      }}
    >
      <S.Pwrapper style={{ marginBottom: "1rem" }}>
        <BaseRow>
          {products &&
            products.map((item: any) => (
              <ProductCard key={item.id} ProductCardData={item} />
            ))}
        </BaseRow>
      </S.Pwrapper>
      <S.Categories style={{ marginTop: "1rem" }}>
        {categories?.map((category: any) => (
          <Card
            key={category.id}
            onClick={() => onSelectCategory(category.id)}
            isSelected={selectedCategory === category.id}
            MenuCardData={category}
          />
        ))}
      </S.Categories>
    </BaseCol>
  );
};

export default PosCategories;

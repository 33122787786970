import styled from 'styled-components';
import { BaseRow } from '../../common/BaseRow/BaseRow';

export const Categories = styled(BaseRow)`
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
`;

export const Pwrapper = styled.div`
    overflow-x: auto; 
    flex: 1;
`;
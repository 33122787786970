import React, { useState } from "react";
import * as S from "./Calculator.styles";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/pos/remove.svg";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import {
  addCurrentTable,
  addOrder,
  cancelOrder,
  clearRequest,
  setCancelReason,
  verifyAdminPIN,
} from "../../../store/slices/ordersSlice";
import { RootState } from "../../../store/store";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { SendReceipt } from "../../../utils/receipts/sendReceipt";
import { ReprintReceipt } from "../../../utils/receipts/reprintReceipt";
import { BaseModal } from "../../common/BaseModal/BaseModal";
import { MoneyCollectOutlined, CreditCardOutlined } from "@ant-design/icons";
import {ClientReceipt} from "../../../utils/receipts/clientReceipt";
import { BaseInput } from "../../common/inputs/BaseInput/BaseInput";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { getXReport, getZReport } from "../../../store/slices/dashboardSlice";
import { XReport } from "../../../utils/receipts/XReport";
import { ZReport } from "../../../utils/receipts/ZReport";
import { notificationController } from "../../../controllers/notificationController";
import { Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";

const CalculatorNumberPad = React.memo(({ onNumberClick }: any) => {
  const order = useSelector((state: RootState) => state.order.request);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isXZreportOpen, setXZreportOpen] = useState(false);
  const [, setPaymentType] = useState<"cash" | "card">("cash");
  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const [result, setResult] = useState("");

  const handleNumberClick = (buttonName: any) => {
    setResult((prevResult) => {
      const newResult = prevResult + buttonName;
      if (onNumberClick) {
        onNumberClick(newResult);
      }
      return newResult;
    });
  };

  const clear = () => {
    setResult("");
  };

  const handlePaymentSelection = (type: "cash" | "card") => {
    setPaymentType(type);
    handlePayment(type);
    setPaymentModalOpen(false);
  };
  const closePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const closeReportsModal = () => {
    setXZreportOpen(false);
  };

  const addnewOrderAction = async (actionName: string) => {
    if (order?.selectedTable === null || order?.selectedTable === undefined) {
      BaseModal.warning({
        title: "Vous n'avez pas sélectionné de table",
        content: "Sélectionnez un tableau s'il vous plaît avant d'ajouter des articles",
      });
    }else {
      if (actionName === "send") {
        const { selectedTable, personsNumber, items, newlyAddedItems, discount, discountReason } = order;
        const formattedData = {
          table_id: selectedTable?.id,
          nbr_personnes: personsNumber,
          ...(discount ? { reduction: discount } : {}),
          ...(discountReason ? { raison: discountReason } : {}),
          articles:
            newlyAddedItems && newlyAddedItems.length > 0
              ? newlyAddedItems.map((item: any) => ({
                  article_id: item.article_id,
                  nom: item.nom,
                  quantite: item.quantite,
                  prix: parseInt(item.prix_total),
                  ...(item.description ? { description: item.description } : {}),
                }))
              : items?.map((item: any) => ({
                  article_id: item.article_id,
                  nom: item.nom,
                  quantite: item.quantite,
                  prix: parseInt(item.prix_total),
                  ...(item.description ? { description: item.description } : {}),
                })),
        };
        const action = await dispatch(addCurrentTable(formattedData));
        console.log(action);
        const orderNumber = action?.payload?.numCommande;
        SendReceipt(order, orderNumber);
        dispatch(clearRequest());
        console.log(order)
      } else if (actionName === "reprint") {
        ReprintReceipt(order);
        dispatch(clearRequest());
      } else if (actionName === "recu") {
        ClientReceipt(order);
        // <ClientReceipt order={order} />
        dispatch(clearRequest());
      } else if (actionName === "pay") {
        if (order?.selectedTable === null || order?.selectedTable === undefined) {
          BaseModal.warning({
            title: "You didn't select a table",
            content: "Sélectionnez un tableau s'il vous plaît avant d'ajouter des articles",
          });
        } else {
          setPaymentModalOpen(true);
        }
      } else if (actionName === "cancel") {
        if (order?.selectedTable === null || order?.selectedTable === undefined) {
          BaseModal.warning({
            title: "Vous n'avez pas sélectionné de table",
            content: "Sélectionnez un tableau s'il vous plaît avant d'ajouter des articles",
          });
        } else {
          setPinModalOpen(true);
        }
      }
    }
    
  };

  const handlePayment = async (type: "cash" | "card") => {
    const { selectedTable, personsNumber, items, total, numCommande, discount, discountReason } = order;
    const formattedData = {
      table_id: selectedTable?.id,
      numCommande: numCommande,
      montant: total,
      nbr_personnes: personsNumber,
      type_payement: type,
      ...(discount ? { reduction: discount } : {}),
      ...(discountReason ? { raison: discountReason } : {}),
      articles: items?.map((item: any) => ({
        article_id: item.article_id,
        quantite: item.quantite,
        ...(item.description ? { description: item.description } : null),
      })),
    };
    await dispatch(addOrder(formattedData));
    ClientReceipt(order, type);
    // <ClientReceipt order={order} type={type} />
    await dispatch(clearRequest());
    setPaymentModalOpen(false);
  };

  const onPinEntered = async (enteredPIN: string) => {
    await dispatch(verifyAdminPIN(enteredPIN))
      .then(async (verified: any) => {
        if (verified.payload) {
          dispatch(setCancelReason(description));
          const { selectedTable, personsNumber, items, total, numCommande } =
            order;
          const formattedData = {
            table_id: selectedTable?.id,
            montant: total,
            nbr_personnes: personsNumber,
            type_payement: "cash",
            numCommande: numCommande,
            pin: enteredPIN,
            ...( description ? { raison: description } : {}),
            articles: items?.map((item: any) => ({
              article_id: item.article_id,
              quantite: item.quantite,
              ...(item.description ? { description: item.description } : {}),
            })),
          };
          await dispatch(cancelOrder(formattedData));
          setPinModalOpen(false);
          dispatch(clearRequest());
          
         
          setDescription("");
          console.log(description);
        } else {
          notificationController.error({ message: "Invalid PIN" });
        }
      })
      .catch((err: any) => {
        console.log({ message: err.message });
      });
  };

  const generateXReport = async () => {
    const xReport = await dispatch(getXReport());
    XReport(xReport.payload);
    setXZreportOpen(false);
  };

  const generateZReport = async () => {
    const zReport = await dispatch(getZReport());
    console.log(zReport);
    ZReport(zReport.payload);
    setXZreportOpen(false);
  };

  return (
    <>
      <S.Container style={{ padding: "1vh", width: "100%" }}>
        <S.Keypad>
          <S.CButton
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
            className="highlight"
            onClick={clear}
            id="backspace"
          >
            C
          </S.CButton>
          <S.CButton
            className="highlight"
            name="/"
            onClick={handleNumberClick}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            .
          </S.CButton>
          <S.CButton
            style={{
              color: "white",
              backgroundColor: "#30323D",
              borderRadius: 0,
              borderColor: "transparent",
            }}
            className="highlight"
            name="/"
            onClick={handleNumberClick}
          >
            <RemoveIcon height={30} />
          </S.CButton>
          <S.CButton
            className="highlight"
            name="/"
            onClick={() => addnewOrderAction("recu")}
            style={{
              color: "white",
              backgroundColor: "#30323D",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            Reçu
          </S.CButton>
          <S.CButton
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
            name="7"
            onClick={() => handleNumberClick(7)}
          >
            7
          </S.CButton>
          <S.CButton
            name="8"
            onClick={() => handleNumberClick(8)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            8
          </S.CButton>
          <S.CButton
            name="9"
            onClick={() => handleNumberClick(9)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            9
          </S.CButton>
          <S.CButton
            className="highlight"
            name="/"
            onClick={() => addnewOrderAction("reprint")}
            style={{
              color: "white",
              backgroundColor: "#30323D",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            Réimprimer
          </S.CButton>
          <S.CButton
            name="4"
            onClick={() => handleNumberClick(4)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            4
          </S.CButton>
          <S.CButton
            name="5"
            onClick={() => handleNumberClick(5)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            5
          </S.CButton>
          <S.CButton
            name="6"
            onClick={() => handleNumberClick(6)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            6
          </S.CButton>
          <S.CButton
            className="highlight"
            name="/"
            onClick={() => addnewOrderAction("send")}
            style={{
              backgroundColor: "#38B6FF",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
            disabled={order.orderStatus === "send" ? true : false}
          >
            Envoyer
          </S.CButton>
          <S.CButton
            name="1"
            onClick={() => handleNumberClick(1)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            1
          </S.CButton>
          <S.CButton
            name="2"
            onClick={() => handleNumberClick(2)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            2
          </S.CButton>
          <S.CButton
            name="3"
            onClick={() => handleNumberClick(3)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            3
          </S.CButton>
          <S.CButton
            className="highlight"
            name="/"
            onClick={() => addnewOrderAction("pay")}
            style={{
              backgroundColor: "#01C592",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            Payer
          </S.CButton>
          <S.CButton
            name="3"
            onClick={() => handleNumberClick(0)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            00
          </S.CButton>
          <S.CButton
            name="0"
            onClick={() => handleNumberClick(0)}
            style={{
              backgroundColor: "#30323D",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            0
          </S.CButton>
          <S.CButton
            className="highlight"
            name="."
            onClick={() => setXZreportOpen(true)}
            style={{
              backgroundColor: "#FFBD59",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            Rapport X/Z
          </S.CButton>
          <S.CButton
            className="highlight"
            name="."
            onClick={() => addnewOrderAction("cancel")}
            style={{
              backgroundColor: "#FF5757",
              color: "white",
              borderRadius: 0,
              borderColor: "transparent",
            }}
          >
            Annuler
          </S.CButton>
        </S.Keypad>
      </S.Container>
      <BaseModal
        open={isPaymentModalOpen}
        onCancel={closePaymentModal}
        title="Choose Payment type"
        footer={null}
      >
        {result && <Typography.Title>{Number(result) - order.total}</Typography.Title>}
        <BaseRow justify={"center"}>
          <BaseButton
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={() => handlePaymentSelection("cash")}
          >
            <MoneyCollectOutlined /> Payer en espèces
          </BaseButton>
          <BaseButton
            onClick={() => handlePaymentSelection("card")}
            type="default"
          >
            <CreditCardOutlined /> Payer par carte
          </BaseButton>
        </BaseRow>
      </BaseModal>
      <BaseModal
        open={isXZreportOpen}
        onCancel={closeReportsModal}
        title="Choisissez le rapport que vous souhaitez extraire"
        footer={null}
      >
        <BaseRow justify={"center"}>
          <BaseButton
            type="primary"
            style={{ marginRight: "5%" }}
            onClick={() => generateXReport()}
          >
            Rapport X
          </BaseButton>
          <BaseButton onClick={() => generateZReport()} type="default">
            Rapport Z
          </BaseButton>
        </BaseRow>
      </BaseModal>

      <BaseModal
        onOk={() => onPinEntered(enteredPIN)}
        size="small"
        title="Vérifier votre PIN"
        open={pinModalOpen}
        onCancel={() => setPinModalOpen(false)}
      >
        <TextArea
            placeholder="Raison de cette Annulation"
            rows={2}
            value={description}
            onChange={(e:any) => setDescription(e.target.value)}
            style={{marginBottom: '1rem'}}
          />
        <BaseCol>
          <BaseInput
            placeholder="Entrer votre PIN"
            type="password"
            value={enteredPIN}
            onChange={(e) => setEnteredPIN(e.target.value)}
          />
        </BaseCol>
      </BaseModal>
    </>
  );
});

export default CalculatorNumberPad;
